import { UseQueryResult, useMutation, useQuery } from '@tanstack/react-query'
import { getList } from '../../service/list'
import { authGoogle } from '../../service/auth'
import { getProfile } from '../../service/user'
import { confirmPayment, getPaymentOrderRequests } from '../../service/payment'

function useConfirmPayment() {
  return useMutation({
    mutationKey: ['confirm-payment'],
    mutationFn: confirmPayment,
  })
}

export default useConfirmPayment
