import { useQuery } from '@tanstack/react-query'
import {
  createInitialMyPointHistoryData,
  createInitialSpendingHistoryData,
  getMyPointHistory,
  getMySpendingHistory,
} from '../../../service/point'

export function useGetMySpendingHistory(page: number, pageSize: number) {
  return useQuery({
    queryKey: ['get-my-spending-history'],
    queryFn: () => getMySpendingHistory(page, pageSize),
    refetchOnWindowFocus: true,
    retry: false,
    staleTime: 1000,
    initialData: createInitialSpendingHistoryData(),
  })
}
