import { useQuery } from '@tanstack/react-query'
import { getMe, getMePlan } from '../../service/user'
import { getShortUsageRequest, getUsageRequest } from '../../service/list'

function useGetShortUsageRequest(page: number, pageSize: number) {
  return useQuery({
    queryKey: ['get-short-usage-request'],
    queryFn: () => getShortUsageRequest(page, pageSize),
    refetchOnWindowFocus: true,
    retry: false,
    initialData: { totalCount: 0, data: [] },
  })
}

export default useGetShortUsageRequest
