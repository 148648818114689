import API from './api'
import { AxiosResponse } from 'axios'

export interface FastCut {
  id: number
  createdAt: string
  updatedAt: string
  deletedAt: string
  userId: number
  bgmId: number
  transitionId: number
  voiceId: number
  aiPromptTypeId: number
  aiToneTypeId: number
  mainTitle: number
  products: []
  linkInfo: [
    {
      link: string
      productName: string
    }
  ]
  youtubeLink: string
  driveLink: string
  contentCreatedAt: string
  uploadedAt: string
}

export interface Sample {
  title: string
  url: string
  price: string
  thumbnail: string
}
export interface ticket {
  id: number
  createdAt: string
  updatedAt: string
  deletedAt: string | null
  userId: number
  used: boolean
}

interface Product {
  link: string
  productName: string
}
export interface VideoInfo {
  mainTitle: string
  voiceId: number
  bgmId: number
  transitionId: number
  aiToneTypeId: number
  aiPromptTypeId: number
  products: Product[]
  check: boolean
}

export interface ShortVideoInfo {
  mainTitle: string
  link: string
  urls: string[]
  voiceId: number
  bgmId: number
  backgroundId: number
  templateId: number
  animationId: number
  aiToneTypeId: number
  aiPromptDurationId: number
  subtitleId: number
  amount: number
  files: File[] | null
}

export interface VoiceGroupFactor {
  id: number
  name: string
  service: string
  planName: string
  usable: boolean
  path: string
  originPrice: number
  price: number
  shortPath: string
  cashRate: number
}

export interface Factor {
  id: number
  name: string
  service: string
  type: string
  usable: boolean
  path: string
}

export interface TransitionFactor {
  id: number
  name: string
  path: string
  planName: string
}

export interface BgmFactor {
  id: number
  name: string
  path: string
  originPrice: string
  price: string
  planName: string
  cashRate: number
}

export interface PopContentType {
  id: number
  title: string
  description: string
  imagePath: string
  url: string
  status: boolean
}
export interface Factors {
  factors: Factor[]
}

export interface PromptDuration {
  id: number
  name: string
  originPrice: string
  price: string
  cashRate: number
}

export interface promptType {
  id: number
  name: string
  description: string
  price: number
  planName: string
}

export interface ToneType {
  id: number
  name: string
  description: string
  originPrice: string
  price: number
  planName: string
}

export interface SubtitleType {
  id: number
  name: string
  originPrice: string
  price: number
  cashRate: number
  planName: string
}

export interface ThumbnailType {
  id: number
  name: string
  planName: string
  path: string
  usable: boolean
}

export interface BackgroundType {
  id: number
  name: string
  path: string
  originPrice: string
  price: string
  cashRate: number
}
export interface ShortUrlData {
  title: string
  images: string[]
}

export interface TemplateItem {
  id: number
  type: string
  name: string
  path: string
  originPrice: string
  price: string
  cashRate: number
}
export interface ShortVideoTemplateType {
  image: {
    list: TemplateItem[]
    cash: string
    originCash: number
  }
  mix: {
    list: TemplateItem[]
    cash: string
    originCash: number
  }
  clip: {
    list: TemplateItem[]
    cash: string
    originCash: number
  }
  etc: {
    list: TemplateItem[]
    cash: string
    originCash: number
  }
}
export interface AnimationType extends BackgroundType {}
export interface UsageRequestFactor {
  fastCutId: number
  mainTitle: string
  createdAt: string
  contentCreatedAt: string
  requestedAt: string
  startedAt: string
  uploadedAt: string
  driveLink: string
  status: string
}
export interface UsageRequest {
  totalCount: number
  data: UsageRequestFactor[]
}

export async function getList() {
  try {
    const data: FastCut[] | [] = await API.get('/fast-cut', {})
    return data
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getUsageRequest(
  page: number,
  pageSize: number
): Promise<UsageRequest> {
  try {
    const data: UsageRequest = await API.get(
      `/fast-cut/usage-request?page=${page}&pageSize=${pageSize}`,
      {}
    )
    return data
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getShortUsageRequest(
  page: number,
  pageSize: number
): Promise<UsageRequest> {
  try {
    const data: UsageRequest = await API.get(
      `/fast-cut-short/usage-request?page=${page}&pageSize=${pageSize}`,
      {}
    )
    return data
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getProducts() {
  try {
    const data: Sample[] = await API.get('/fast-cut/samples', {})
    return data
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function postList(formData: FormData) {
  try {
    if (formData.get('check')) {
      const result = await API.post('/fast-cut/form', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return result
    } else {
      throw Error('요청 불가')
    }
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function _postList(data: VideoInfo) {
  try {
    if (data.check) {
      const result = await API.post('/fast-cut', data)
      return result
    } else {
      throw Error('요청 불가')
    }
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function createShortVideo(formData: FormData) {
  try {
    const result = await API.post('/fast-cut-short/form', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return result
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function _createShortVideo(data: ShortVideoInfo) {
  try {
    const result = await API.post('/fast-cut-short', data)
    return result
  } catch (error) {
    // 오류 처리
    throw error
  }
}
export async function getTicket() {
  try {
    const data: ticket | undefined = await API.get('/fast-cut/ticket')
    return data
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function createVideo(ticketId: number) {
  try {
    return await API.put(`/fast-cut/ticket/${ticketId}`, {})
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getBgm(): Promise<BgmFactor[]> {
  try {
    return await API.get(`/fast-cut/bgm/`, {})
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getVoice(): Promise<VoiceGroupFactor[]> {
  try {
    return await API.get(`/fast-cut/voice-group`, {})
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getTransition(): Promise<TransitionFactor[]> {
  try {
    return await API.get(`/fast-cut/transition`, {})
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getAIPromptType(): Promise<promptType[]> {
  try {
    return await API.get(`/fast-cut/ai-prompt-type`, {})
  } catch (error) {
    // 오류 처리
    throw error
  }
}
export async function getAIPromptDuration(): Promise<PromptDuration[]> {
  try {
    return await API.get(`/fast-cut/ai-prompt-duration`, {})
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getMakeTotal(): Promise<any> {
  try {
    return await API.get(`/fast-cut/make-total`, {})
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getAIToneLongType(type: string): Promise<ToneType[]> {
  try {
    return await API.get(`/fast-cut/ai-tone-type/${type}`, {})
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getAIToneShortType(type: string): Promise<ToneType[]> {
  try {
    return await API.get(`/fast-cut/ai-tone-type/${type}`, {})
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getThumbnail(): Promise<ThumbnailType[]> {
  try {
    return await API.get(`/fast-cut/thumbnail`, {})
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getBackgroundList(): Promise<BackgroundType[]> {
  try {
    return await API.get(`/fast-cut/background`, {})
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getAnimationList(): Promise<AnimationType[]> {
  try {
    return await API.get(`/fast-cut/animation`, {})
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getShortImageList(url: string): Promise<ShortUrlData> {
  try {
    return await API.get(`/crawling/short-url?url=${encodeURIComponent(url)}`)
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getShortVideoTemplate(): Promise<ShortVideoTemplateType> {
  try {
    return await API.get(`/fast-cut/template`)
  } catch (error) {
    // 오류 처리
    throw error
  }
}
export async function getSubTitle(): Promise<SubtitleType[]> {
  try {
    return await API.get(`/fast-cut/subtitle`, {})
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getPopupContent(): Promise<PopContentType> {
  try {
    return await API.get(`/popup`, {})
  } catch (error) {
    // 오류 처리
    throw error
  }
}
