import axios from 'axios'
import API from './api'

export interface InsertLinkDto {
  link: string
  challengeDetailId: number
}

export interface ChallengeDetailType {
  id: number
  createdAt: string
  updatedAt: string
  deletedAt: string
  challengeId: number
  term: number
  link: string
}

export interface ChallengeType {
  id: number
  createdAt: string
  updatedAt: string
  deletedAt: string
  userId: number
  userSubscriptionId: number
  link: string
  challengeDetail: ChallengeDetailType[]
}

export async function createChallenge(data: any) {
  try {
    return await API.post('/challenge', data)
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function updateChallenge(data: any) {
  try {
    return await API.put('/challenge', data)
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getChallenge(): Promise<ChallengeType> {
  try {
    return await API.get('/challenge')
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function insertLink(data: InsertLinkDto) {
  try {
    return await API.post('/challenge/link', data)
  } catch (error) {
    // 오류 처리
    throw error
  }
}
