import React, { PropsWithChildren } from 'react'
{
  /* <div className="border md:rounded-[20px] border-[#EEF1F7] md:p-[40px] shadow-main bg-[#FFFFFF]"> */
}
export const ShadowBox = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => {
  return (
    <div
      className={
        className
          ? ` ${className} border md:rounded-[20px] border-[#EEF1F7] md:p-[40px] shadow-main`
          : 'border md:rounded-[20px] border-[#EEF1F7] md:p-[40px] shadow-main'
      }
    >
      {children}
    </div>
  )
}
