import React, { PropsWithChildren } from 'react'

export const My = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => {
  return (
    <div className="flex font-bold text-[#5E6068] gap-[5px]">
      <p>MY</p>
      <p>{`>`}</p>
      {children}
    </div>
  )
}
