import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { createVideo } from '../../service/list'
import {
  discountCodeCheck,
  updatePaymentOrderRequest,
} from '../../service/payment'

export const useSaveDiscount = () => {
  return useMutation({
    mutationKey: ['put-discount'],
    mutationFn: updatePaymentOrderRequest,
  })
}
