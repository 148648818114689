import { useQuery } from '@tanstack/react-query'
import {
  getPlans,
  getUsageAnnual,
  getUsageMonthly,
} from '../../../service/plans'

function useGetUsageAnnual() {
  return useQuery({
    queryKey: ['get-usage-annual'],
    queryFn: () => getUsageAnnual(),
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 600000,
  })
}

export default useGetUsageAnnual
