import { useQuery } from '@tanstack/react-query'
import { getBgm } from '../../service/list'

function useGetBgm() {
  return useQuery({
    queryKey: ['get-bgm'],
    queryFn: () => getBgm(),
    staleTime: 6 * 1000,
  })
}

export default useGetBgm
