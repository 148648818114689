import React from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts'
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'
import { dailyDummyData } from '../../dummy/data'

interface ChartData {
  usageDate: string
  usedCount: number
}
export const ShortsVideoDailyBarChart = (props: any) => {
  const { barWidth } = props
  // 이번 달의 마지막 날짜 계산 함수
  const getDaysInCurrentMonth = (): number => {
    const now = new Date(2)
    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate()

    return lastDay
  }

  // 이번 달 일 수에 맞는 데이터를 생성
  const daysInMonth = getDaysInCurrentMonth()
  const generateMonthlyData = (days: number): ChartData[] => {
    return Array.from({ length: days }, (_, i) => ({
      usageDate: `${i + 1}`,
      usedCount: Math.floor(Math.random() * 100), // 실제 데이터가 없을 경우 0으로 설정 가능
    }))
  }

  const data = dailyDummyData

  const CustomTooltip: React.FC<TooltipProps<ValueType, NameType>> = ({
    active,
    payload,
    label,
  }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            padding: '10px',
            borderRadius: '5px',
          }}
        >
          <p>{`${label}일차`}</p>
          <p>{`${payload[0].value}개 요청`}</p>
        </div>
      )
    }

    return null
  }
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data} height={80} width={394}>
        {/* X축 설정: 1일, 마지막 일, 5일 간격 표시 */}
        <XAxis
          dataKey="date"
          ticks={[
            '1',
            '5',
            '10',
            '15',
            '20',
            '25',
            `${getDaysInCurrentMonth()}`,
          ]}
          tick={{ fontSize: 12, fill: '#D4D9DF' }}
          axisLine={false} // X축 라인 제거
          tickLine={false} // X축 눈금선 제거
        />

        {/* Y축 숨기기 */}
        <YAxis hide />

        <Tooltip content={<CustomTooltip />} />

        {/* Bar 설정 */}

        <Bar dataKey="usedCount" radius={[10, 10, 10, 10]} stackId="a">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} width={barWidth} fill="url(#colorUv)" />
          ))}
        </Bar>

        {/* 그라데이션 설정 */}
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="rgba(89, 144, 255, 1)" />
            <stop offset="95%" stopColor=" rgba(0, 227, 225, 1)" />
          </linearGradient>
        </defs>
      </BarChart>
    </ResponsiveContainer>
  )
}

export const LongVideoDailyBarChart = (props: any) => {
  const { barWidth, chartData } = props
  // 이번 달의 마지막 날짜 계산 함수
  const getDaysInCurrentMonth = (): number => {
    const now = new Date()
    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate()

    return lastDay
  }

  // 이번 달 일 수에 맞는 데이터를 생성
  const daysInMonth = getDaysInCurrentMonth()
  const generateMonthlyData = (days: number): ChartData[] => {
    return Array.from({ length: days }, (_, i) => ({
      usageDate: `${i + 1}`,
      usedCount: Math.floor(Math.random() * 100), // 실제 데이터가 없을 경우 0으로 설정 가능
    }))
  }

  const data = generateMonthlyData(daysInMonth)

  const CustomTooltip: React.FC<TooltipProps<ValueType, NameType>> = ({
    active,
    payload,
    label,
  }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            padding: '10px',
            borderRadius: '5px',
          }}
        >
          <p>{`${label}일차`}</p>
          <p>{`${payload[0].value}개 요청`}</p>
        </div>
      )
    }

    return null
  }
  return (
    <ResponsiveContainer className=" ">
      <BarChart data={chartData}>
        {/* X축 설정: 1일, 마지막 일, 5일 간격 표시 */}
        <XAxis
          dataKey="usageDate"
          tick={{ fontSize: 12, fill: '#D4D9DF' }}
          axisLine={false} // X축 라인 제거
          tickLine={false} // X축 눈금선 제거
          hide
        />

        {/* Y축 숨기기 */}
        <YAxis hide />

        <Tooltip content={<CustomTooltip />} />

        {/* Bar 설정 */}
        <Bar dataKey="usedCount" radius={[10, 10, 10, 10]} stackId="a">
          {chartData?.map((entry: any, index: number) => (
            <Cell key={`cell-${index}`} width={barWidth} fill="url(#colorUv)" />
          ))}
        </Bar>

        {/* 그라데이션 설정 */}
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="rgba(89, 144, 255, 1)" />
            <stop offset="95%" stopColor=" rgba(0, 227, 225, 1)" />
          </linearGradient>
        </defs>
      </BarChart>
    </ResponsiveContainer>
  )
}
