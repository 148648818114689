import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { PaymentModal } from '../components/PaymentModal'
import {
  AffiliateBenefit,
  CouponBenefit,
  DefaultBenefit,
  InviteBenefit,
  PaymentOrderRequest,
} from '../service/payment'
import useUserStore from '../store/useUserStore'
import useGetPaymentOrderRequests from '../hooks/query/useGetPaymentOrderRequests'
import { SubscriptionPlan, subscriptionType } from '../service/plans'
import { UserType } from '../utils/common'

export const Challenge = () => {
  const navigate = useNavigate()
  const userInfo = useUserStore((state: any) => state.userInfo)
  const [paymentModal, setPaymentModal] = useState<boolean>(false)
  const [type, isType] = useState('annual')
  const [paymentOrderRequest, setPaymentOrderRequest] =
    useState<PaymentOrderRequest>({
      orderId: '',
      productName: '',
      subscriptionType: '',
      price: 0,
      vat: 0,
      id: 0,
    })
  const [affiliateBenefit, setAffiliateBenefit] =
    useState<null | AffiliateBenefit>(null)
  const [defaultBenefit, setDefaultBenefit] = useState<null | DefaultBenefit>(
    null
  )

  const [couponBenefit, setCouponBenefit] = useState<null | CouponBenefit>(null)
  const [isUpgrade, setUpgrade] = useState<boolean>(false)
  const [monthPriceValue, setMonthPriceValue] = useState(0)
  const [inviteBenefit, setInviteBenefit] = useState<null | InviteBenefit>(null)
  const [planIdValue, setPlanId] = useState(0)
  const [isPlan, setIsPlan] = useState<boolean>(false)
  const { mutate: getPaymentOrderRequestsMutate } = useGetPaymentOrderRequests()
  const getPaymentOrderRequests = (planId: number, planPriceId: number) => {
    setPlanId(planId)
    if (!userInfo?.userInfo) {
      navigate('/sign-up')
      return
    }

    // 업그레이드 조건 클릭한 planId와 현재 사용중인 planId가 같을경우에는 상관없지
    if (
      userInfo?.subscriptions?.subscriptionType === subscriptionType.MONTHLY &&
      userInfo?.subscriptions?.planId === planId &&
      type === subscriptionType.ANNUAL
    ) {
      setUpgrade(true)
      setMonthPriceValue(Number(userInfo?.subscriptions?.price))
    } else if (
      // 월결제 달성 후 다시 월결제 조건
      userInfo?.isDone &&
      userInfo?.subscriptions?.planId === planId
    ) {
    } else {
      if (
        !(
          userInfo?.userType === UserType.USER ||
          userInfo?.userType === UserType.체험
        )
      ) {
        setIsPlan(true)
        return
      }
    }

    getPaymentOrderRequestsMutate(
      { planId, planPriceId },
      {
        onSuccess: (data) => {
          setPaymentOrderRequest(data.orderRequest)
          if (data.affiliateBenefit) {
            setAffiliateBenefit(data.affiliateBenefit)
          }
          if (data?.couponBenefit) {
            setCouponBenefit(data.couponBenefit)
          }
          if (data?.inviteBenefit) {
            setInviteBenefit(data.inviteBenefit)
          }
          if (data?.defaultBenefit && type === 'annual') {
            setDefaultBenefit(data.defaultBenefit)
          }
          setPaymentModal(true)
        },
        onError: (e) => {
          console.log(e)
        },
      }
    )
  }
  const moveToPriceGuide = () => {
    navigate('/price-guide')
  }

  const handleCopyClipBoard = async (copyText: string, successText: string) => {
    try {
      await navigator.clipboard.writeText(copyText)
      toast.success(successText, {
        position: 'bottom-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    } catch (e) {}
  }

  const handleDownloadAll = () => {
    const files = [
      '/assets/img/challenge/item1.png',
      '/assets/img/challenge/item2.png',
      '/assets/img/challenge/item3.png',
    ]
    files.forEach((file, index) => {
      setTimeout(() => {
        const link = document.createElement('a')
        link.href = process.env.PUBLIC_URL + file
        link.download = `fast_cut_challenge_image${index + 1}.jpg`
        link.click()
      }, index * 1000) // 각 파일 다운로드 간 1초 간격
    })
  }

  const handleDownload = (url: string, index: number) => {
    const link = document.createElement('a')
    link.href = process.env.PUBLIC_URL + url
    link.download = `fast_cut_challenge_image${index + 1}.jpg`
    link.click()
  }

  return (
    <div className="md:min-w-[100vw] w-full bg-[#181830] flex justify-center  h-auto pb-10">
      <div className="md:w-[816px] w-full flex flex-col items-center ">
        {isMobile ? (
          <>
            <div
              className="image1 w-full bg-cover bg-center md:h-[1116px] h-[583px] flex justify-center items-end"
              style={{
                backgroundImage:
                  "url('assets/img/challenge/payback_challenge1_mobile.png')",
              }}
            >
              <button
                className="w-[335px] text-[#222222] text-[18px] h-[60px] rounded-[60px] font-bold mb-5"
                style={{
                  background:
                    'linear-gradient(to top, rgba(229, 169, 7, 1),rgba(237, 224, 147, 1))',
                  border: '2px solid rgba(255, 255, 255 , 0.4)',
                }}
                onClick={moveToPriceGuide}
              >
                지금 결제하고 챌린지 참여 {`>`}
              </button>
            </div>
          </>
        ) : (
          <>
            <div
              className="image1 w-full bg-cover bg-center md:h-[1116px] h-[583px] flex justify-center items-end"
              style={{
                backgroundImage:
                  "url('assets/img/challenge/payback_challenge1.png')",
              }}
            >
              <button
                className="w-[335px] text-[#222222] text-[18px] h-[60px] rounded-[60px] font-bold md:mb-14"
                style={{
                  background:
                    'linear-gradient(to top, rgba(229, 169, 7, 1),rgba(237, 224, 147, 1))',
                  border: '2px solid rgba(255, 255, 255 , 0.4)',
                }}
                onClick={moveToPriceGuide}
              >
                지금 결제하고 챌린지 참여 {`>`}
              </button>
            </div>
          </>
        )}

        {isMobile ? (
          <>
            {' '}
            <div className="image2 md:w-full ">
              <img
                src="assets/img/challenge/payback_challenge2_mobile.png"
                alt=""
              />
            </div>
          </>
        ) : (
          <>
            {' '}
            <div className="image2 md:w-full ">
              <img src="assets/img/challenge/payback_challenge2.png" alt="" />
            </div>
          </>
        )}
        {isMobile ? (
          <>
            {' '}
            <div
              className="image3_mobile w-full bg-cover bg-center h-[523px]"
              style={{
                backgroundImage:
                  "url('assets/img/challenge/payback_challenge3_mobile.png')",
              }}
            >
              <div className="mt-[82px]">
                <p className="text-[#FFE89E] font-[500]  text-center">
                  1년간 미션 완수하면
                </p>
                <p className="text-[34px] font-[800] font-[Tenada] text-[white] text-center mt-[8px] leading-[34px]">
                  연간 결제 금액 <br />{' '}
                  <span className=" underline underline-offset-4 ">
                    전액 페이백
                  </span>
                </p>
              </div>
              <div className="flex justify-center mt-[11.83px]">
                <img
                  src="assets/img/challenge/payback_challenge3_1.png"
                  alt=""
                  width={148}
                />
              </div>
              <div className="caution 4 w-full flex justify-center gap-4  mt-[148px] ">
                <button
                  className=" text-[#222222] text-[18px]  w-[315.66px] h-[56.54px] rounded-[60px] border-[2px] border-[#FFFFFF66] font-bold border-opacity-50 "
                  style={{
                    background:
                      'linear-gradient(to top, rgba(229, 169, 7, 1),rgba(237, 224, 147, 1))',
                    border: '2px solid rgba(255, 255, 255 , 0.4)',
                  }}
                  onClick={moveToPriceGuide}
                >
                  지금 결제하고 페이백 받기 {`>`}
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            {' '}
            <div
              className="image3 w-full bg-cover bg-center h-[695px]"
              style={{
                backgroundImage:
                  "url('assets/img/challenge/payback_challenge3.png')",
              }}
            >
              <div className="mt-[25px]">
                <p className="text-[#FFE89E] font-[500] text-[24px] text-center">
                  1년간 미션 완수하면
                </p>
                <p className="text-[40px] font-[800] font-[Tenada] text-[white] text-center mt-[14px] leading-[40px]">
                  연간 결제 금액 <br />{' '}
                  <span className=" underline underline-offset-4 ">
                    전액 페이백
                  </span>
                </p>
              </div>
              <div className="flex justify-center mt-[17.83px]">
                <img
                  src="assets/img/challenge/payback_challenge3_1.png"
                  alt=""
                  width={262.84}
                />
              </div>
              <div className="caution 4 w-full flex justify-center gap-4  mt-[21.51px] ">
                <button
                  className=" text-[#222222] text-[18px]  w-[315.66px] h-[56.54px] rounded-[60px] font-bold  "
                  style={{
                    background:
                      'linear-gradient(to top, rgba(229, 169, 7, 1),rgba(237, 224, 147, 1))',
                    border: '2px solid rgba(255, 255, 255, 0.4)',
                  }}
                  onClick={moveToPriceGuide}
                >
                  지금 결제하고 페이백 받기 {`>`}
                </button>
              </div>
            </div>
          </>
        )}
        {isMobile ? (
          <>
            {' '}
            <div
              className="image4 w-full bg-cover bg-center h-[608px] md:h-[640px] flex justify-center items-end"
              style={{
                backgroundImage:
                  "url('assets/img/challenge/payback_challenge4_mobile.png')",
              }}
            >
              <button
                className="w-[335px] text-[18px] h-[60px] rounded-[60px] font-bold mb-[34.5px]"
                style={{
                  background:
                    'linear-gradient(to top, rgba(229, 169, 7, 1),rgba(237, 224, 147, 1))',
                  border: '2px solid rgba(255, 255, 255 , 0.4)',
                }}
                onClick={moveToPriceGuide}
              >
                지금 결제하고 럭키드로우 뽑기 {`>`}
              </button>
            </div>
          </>
        ) : (
          <>
            {' '}
            <div
              className="image4 w-full bg-cover bg-center h-[608px] md:h-[640px] flex justify-center items-end"
              style={{
                backgroundImage:
                  "url('assets/img/challenge/payback_challenge4.png')",
              }}
            >
              <button
                className="w-[335px] text-[18px] h-[60px] rounded-[60px] font-bold mb-[34.5px]"
                style={{
                  background:
                    'linear-gradient(to top, rgba(229, 169, 7, 1),rgba(237, 224, 147, 1))',
                  border: '2px solid rgba(255, 255, 255 , 0.4)',
                }}
                onClick={moveToPriceGuide}
              >
                지금 결제하고 럭키드로우 뽑기 {`>`}
              </button>
            </div>
          </>
        )}
        <div className="flex flex-col bg-[#0A0A2A] px-5 py-8">
          <div className="challenge_boon 4 md:w-full w-[335px] flex flex-col  gap-4 mt-8 ">
            <p className="font-[800] text-[24px] font-[Tenada] text-white">
              챌린지 혜택
            </p>
            <ul className=" list-disc text-[#B6BBC6] ml-4">
              <li>
                1년간 모든 미션을 완수하면 연간 결제금액의
                전액(제세공과금제외)을 페이백 합니다
              </li>
              <li>미션 달성시 라운드별 보너스 캐시를 뽑을 수 있습니다.</li>
              <li>
                개인별 초대코드가 부여되며, 초대받은 친구가 패스트컷 서비스를
                결제할 시 결제금액의 일정 비율만큼 캐시가 적립됩니다.
              </li>
            </ul>
          </div>
          <div className="challenge_mission md:w-full w-[335px] flex flex-col gap-4  mt-8">
            <p className="font-[800] text-[24px] font-[Tenada] text-white">
              챌린지 미션 3단계
            </p>
            <div className="flex flex-col gap-2">
              <div className="bg-[#2D2D4E] text-[#D4D9DF] md:p-5 p-[10px] rounded-[10px]">
                <p className="text-main font-[800] font-[Tenada] text-[20px]">
                  STEP 1. 챌린지 시작!
                </p>
                <p className=" font-bold text-[#D4D9DF]">
                  챌린지 참여 선언 유튜브 영상 or 블로그 생성하기
                </p>
                <p className="text-[#B6BBC6] mt-2">
                  챌린지 참여와 미션 100% 완수하기를 다짐하는{` `}
                  <span className="text-main font-bold">
                    유튜브 영상이나 블로그 포스트를 업로드하고, 링크
                  </span>
                  를 사용현황 페이지{`>`}챌린지 참여선언 버튼을 눌러 팝업 내
                  입력란에 입력 해주세요!
                </p>

                <p className="text-[#B6BBC6] mt-2">
                  내용은 자유롭게! 아래 제목과 이미지, 내용을 복사해서
                  사용하셔도 됩니다.
                </p>
                <div className="flex flex-col mt-2 gap-2">
                  <div className="w-full bg-white rounded-[6px] h-[63px] flex items-center px-[10px] justify-between">
                    <p className="text-[#5E6068] w-[225px] md:w-[706px]">
                      제목 : 패스트컷 AI 100% 페이백 챌린지 참여 선언!
                    </p>
                    <button
                      className="bg-[#6B8ACB] hover:bg-[#8FABE5] active:scale-95 text-white    w-[60px] h-[43px]  
                     flex items-center justify-center rounded-[10px] font-bold disabled:opacity-[0.3]"
                      type="button"
                      onClick={() => {
                        handleCopyClipBoard(
                          `제목 : 패스트컷 AI 100% 페이백 챌린지 참여 선언!`,
                          '복사 완료'
                        )
                      }}
                    >
                      복사
                    </button>
                  </div>
                  <div className="w-full bg-white rounded-[6px] md:h-[80px] flex flex-col  md:flex-row items-center p-[10px] justify-between">
                    <p className="text-[#5E6068] md:w-[706px] ">
                      내용 : 패스트컷 AI 100% 페이백 챌린지에 참여하기로 했어요!
                      패스트컷은 유튜브 + 쿠팡파트너스 알리 어필리에이트 수익화
                      하는 가장 쉽고 빠른 방법 이라고 생각합니다. 오늘부터
                      꾸준히 영상을 생성해서 월 80개씩 1년동안 업로드 하려고
                      해요. 어렵겠지만 꼭 성공해서 전액 페이백 받을 수 있게
                      응원해주세요!
                    </p>
                    <button
                      className="bg-[#6B8ACB] hover:bg-[#8FABE5] active:scale-95 text-white  w-[60px] h-[43px]  
                     flex items-center justify-center rounded-[10px] font-bold disabled:opacity-[0.3] mt-[10px] md:mt-0"
                      type="button"
                      onClick={() => {
                        handleCopyClipBoard(
                          `내용 : 패스트컷 AI 100% 페이백 챌린지에 참여하기로 했어요!
                    패스트컷은 유튜브 + 쿠팡파트너스 알리 어필리에이트 수익화
                    하는 가장 쉽고 빠른 방법 이라고 생각합니다. 오늘부터 꾸준히
                    영상을 생성해서 월 80개씩 1년동안 업로드 하려고 해요.
                    어렵겠지만 꼭 성공해서 전액 페이백 받을 수 있게
                    응원해주세요!`,
                          '복사 완료'
                        )
                      }}
                    >
                      복사
                    </button>
                  </div>
                  <div className="w-full bg-white rounded-[6px] h-[170px] md:h-[117px] justify-center  items-center flex md:flex-row md:items-center px-[10px] md:justify-between gap-[2px]">
                    {isMobile ? (
                      <>
                        <p className="text-[#5E6068] flex flex-col  items-center">
                          <img
                            src="assets/img/challenge/item1.png"
                            alt=""
                            width={97}
                            height={97}
                          />
                          <button
                            className="bg-[#6B8ACB] hover:bg-[#8FABE5] active:scale-95 text-white  w-[88px] h-[43px]    mt-[10px]   
                     flex items-center justify-center rounded-[10px] font-bold disabled:opacity-[0.3]"
                            type="button"
                            onClick={() => {
                              handleDownload(
                                'assets/img/challenge/item1.png',
                                1
                              )
                            }}
                          >
                            다운로드
                          </button>
                        </p>
                        <p className="text-[#5E6068] flex flex-col items-center">
                          <img
                            src="assets/img/challenge/item2.png"
                            alt=""
                            className="w-[97px] h-[97px]"
                          />
                          <button
                            className="bg-[#6B8ACB] hover:bg-[#8FABE5] active:scale-95 text-white  w-[88px] h-[43px]  mt-[10px]   
                     flex items-center justify-center rounded-[10px] font-bold disabled:opacity-[0.3]"
                            type="button"
                            onClick={() => {
                              handleDownload(
                                'assets/img/challenge/item2.png',
                                1
                              )
                            }}
                          >
                            다운로드
                          </button>
                        </p>
                        <p className="text-[#5E6068] flex flex-col  items-center">
                          <img
                            src="assets/img/challenge/item3.png"
                            alt=""
                            className="w-[97px] h-[97px]"
                          />
                          <button
                            className="bg-[#6B8ACB] hover:bg-[#8FABE5] active:scale-95 text-white  w-[88px] h-[43px] mt-[10px]   
                     flex items-center justify-center rounded-[10px] font-bold disabled:opacity-[0.3]"
                            type="button"
                            onClick={() => {
                              handleDownload(
                                'assets/img/challenge/item3.png',
                                1
                              )
                            }}
                          >
                            다운로드
                          </button>
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="text-[#5E6068] flex gap-[2px]">
                          <img
                            src="assets/img/challenge/item1.png"
                            alt=""
                            className="w-[97px] h-[97px]"
                          />
                          <img
                            src="assets/img/challenge/item2.png"
                            alt=""
                            className="w-[97px] h-[97px]"
                          />
                          <img
                            src="assets/img/challenge/item3.png"
                            alt=""
                            className="w-[97px] h-[97px]"
                          />
                        </p>
                        <button
                          className="bg-[#6B8ACB] hover:bg-[#8FABE5] active:scale-95 text-white  w-[133px] h-[43px] mt-[10px]   md:mt-0
                     flex items-center justify-center rounded-[10px] font-bold disabled:opacity-[0.3]"
                          type="button"
                          onClick={handleDownloadAll}
                        >
                          이미지 다운로드
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="bg-[#2D2D4E] text-[#D4D9DF] md:p-5 p-[10px] rounded-[10px] flex flex-col gap-[10px]">
                <p className="text-main font-[800] font-[Tenada] text-[20px]">
                  STEP 2. 첫 3개월 성공의 습관 형성
                </p>
                <p className=" font-bold text-[#D4D9DF]">
                  3라운드까지 각 라운드별 영상 생성 + 업로드 100% 완료하기
                </p>
                <ul className=" list-disc ml-4 text-[#B6BBC6]">
                  <li>
                    1~3개월 동안 수익 창출이 될 씨앗을 심어야 해요. 가장
                    에너지있게 도전해야 할 시기입니다.
                  </li>
                  <li>
                    패스트컷 연간 플랜은 사용 1개월 단위를 ‘라운드'라고 불러요.{' '}
                  </li>
                  <li>
                    1, 2, 3라운드에 각각 영상 80개씩을 생성 요청 해주세요.
                  </li>
                  <li>자동업로드 설정은 필수로 해주세요.</li>
                </ul>
              </div>

              <div className="bg-[#2D2D4E] text-[#D4D9DF] md:p-5 p-[10px] rounded-[10px] flex flex-col gap-[10px]">
                <p className="text-main font-[800] font-[Tenada] text-[20px]">
                  STEP 3. 체계적인 수익 관리와 캐시 쌓기
                </p>
                <p className=" font-bold text-[#D4D9DF]">
                  월2회 수익 인증 유튜브 영상 or 블로그 생성하기
                </p>
                <p className="text-[#B6BBC6]">
                  정확하고 체계적인 관리는 수익 상승의 필수조건입니다.{' '}
                  <span className="text-white font-bold">매월 1일, 15일</span>에{' '}
                  쿠팡 파트너스나 알리 어필리에이트의{' '}
                  <span className="text-main font-bold">
                    수익을 유튜브 영상이나 블로그 포스트로 정리해서 인증
                  </span>
                  하고, 해당 링크를 인증 페이지에서 입력해주세요.
                </p>
                <ul className=" list-disc ml-4 text-[#B6BBC6]">
                  <li className="">
                    <span className="font-bold text-white">
                      STEP1, STEP2 미션을 100% 완료한 경우, 4라운드부터
                    </span>{' '}
                    수익인증 기능이 활성화됩니다.
                  </li>
                  <li>실제 수익이 있어야 합니다. (쿠팡/알리 합산 가능)</li>
                  <li>
                    수익 인증한 영상이나 포스트는 전체 공개 설정 해주세요.
                  </li>
                  <li>
                    플랫폼 이름(쿠팡/알리), 해당 주, 수익금액을 해당 플랫폼의
                    주의사항을 고려하여 명시해주세요.
                  </li>
                  <li>
                    실제 인증임이 인정되는 화면 촬영, 캡쳐 등을 포함해주세요.
                  </li>
                  <li>
                    본인의 패스트컷 초대코드를 함께 안내해주세요. 초대 결제가
                    일어나면 결제금액의 일정 비율만큼 캐시가 적립돼요.
                  </li>
                  <li>총 18회의 인증을 모두 완료해주세요.</li>
                  <li>
                    매 라운드 1일, 15일 오후 11시 59분 59초까지 수익인증 컨텐츠
                    발행과 링크 입력을 완료해야 인정됩니다. 이외의 경우에는 수익
                    인증으로 인정되지 않습니다.
                  </li>
                  <li>
                    컨텐츠 발행과 링크 입력 날짜는 관리자와 시스템이 판별합니다.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="challenge_qualifications 4 md:w-full w-[335px] flex flex-col gap-4  mt-8">
            <p className="font-[800] text-[24px] font-[Tenada] text-white">
              참여 자격
            </p>
            <ul className=" list-disc text-[#B6BBC6] ml-4">
              <li>패스트컷 신규회원</li>
              <li>프로/프리미엄 연간 결제 회원</li>
              <li>선착순 1,000명 한정</li>
            </ul>
          </div>
          <div className="caution md:w-full w-[335px] flex flex-col gap-4  mt-8">
            <p className="font-[800] text-[24px] font-[Tenada] text-white">
              기타 주의사항
            </p>
            <ul className=" list-disc text-[#B6BBC6] ml-4">
              <li>1년간 플랜 사용을 유지해야 합니다.</li>
              <li>
                최초 연간 결제금액에서 관련 제세공과금을 제외하고 환급됩니다.
              </li>
              <li>
                관리자가 참여 선언, 매 라운드 생성/업로드 개수, 업로드된 동영상
                유지, 수익 인증 여부 등을 확인합니다.
              </li>
              <li>
                사용기간 내 업로드된 영상은 1년 사용이 종료될 때까지 공개
                유지해주세요.{' '}
              </li>
              <li>
                동일한 상품 링크나 제목을 반복적으로 사용하는 등의 부당 행위가
                발견될 경우 챌린지 참여 자격이 유지되지 않습니다.
              </li>
              <li>
                부당 행위로 판단되는 수익 인증은 미션 수행으로 인정되지
                않습니다.
              </li>
              <li>
                1년간 미션 100% 성공한 회원의 페이백 신청 방법은 개별 메일로
                안내됩니다.
              </li>
              <li>페이백 신청 후 2달 이내에 페이백 완료됩니다.</li>
              <li>
                기존 프로/프리미엄 연간플랜 회원은 아래 링크에 가입 이메일을
                입력하여 개별 문의해주세요.
                <br />
                https://forms.gle/FzvAjoJHPfbnQuop8
              </li>
            </ul>
          </div>
          {(!userInfo ||
            userInfo?.userType === UserType.체험 ||
            userInfo?.userType === UserType.USER) && (
            <div className="caution 4 w-full flex gap-4  mt-8 justify-center">
              <button
                className="md:w-full w-[335px] text-[18px] h-[60px] rounded-[60px] font-bold"
                style={{
                  background:
                    'linear-gradient(to top, rgba(229, 169, 7, 1),rgba(237, 224, 147, 1))',
                  border: '2px solid rgba(255, 255, 255 , 0.4)',
                }}
                onClick={() => {
                  getPaymentOrderRequests(3, 6)
                }}
              >
                BEST 플랜 바로 결제하고 페이백 받기 {`>`}
              </button>
            </div>
          )}
        </div>
      </div>
      {paymentModal && (
        <PaymentModal
          setPaymentModal={setPaymentModal}
          paymentOrderRequest={paymentOrderRequest}
          affiliateBenefit={affiliateBenefit}
          inviteBenefit={inviteBenefit}
          affiliatePlan={userInfo?.affiliate?.plan}
          couponBenefit={couponBenefit}
          setCouponBenefitData={setCouponBenefit}
          setAffiliateBenefitData={setAffiliateBenefit}
          userInfo={userInfo?.userInfo}
          planId={planIdValue}
          type={'annual'}
          upgrade={isUpgrade}
          monthPrice={monthPriceValue}
          defaultBenefit={defaultBenefit}
          setDefaultBenefitData={setDefaultBenefit}
        />
      )}
    </div>
  )
}
