import API from './api'
export interface UsageListType {
  charts: UsageChartItem[]
  subscriptions: UsageSubscriptions
  mission: UsageMission
  achievement?: UsageAchievement
  usage: {
    planLimit: number
    totalLimit: number
    userLimit: string
    usedCount: string
    isDone: boolean
    available: boolean
  }
}
export interface UsageChartItem {
  usageDate: string
  usedCount: number
}
export interface UsageSubscriptions {
  id: number
  planName: string
  price: string
  userId: number
  planId: number
  startDate: string
  endDate: string
  subscriptionType: string
  daysLeft: number
  currentMonth: string
  monthStart: string
  monthEnd: string
}
export interface UsageAchievement {
  createdAt: string
  deletedAt: string
  expireAt: string
  id: number
  pointAmount: string
  pointId: string
  round: number
  updatedAt: number
  userId: number
  userSubscriptionId: number
}

export interface UsageMission {
  result: boolean
  monthEndDate: string
  daysLeft: string
  achievementId: null | number
  luckyDrawAvaliable: boolean
  pointAmount: null | number
  planLimit: number
  usedCount: number
  remainCount: number
}

export const subscriptionType = {
  MONTHLY: 'monthly',
  SEMIANNUAL: 'semiannual',
  ANNUAL: 'annual',
} as const

export interface PlanDetailType {
  type: string
  name: string
  highlight: boolean
}

export interface Plan {
  id: number
  name: string
  paidType?: string
  planDetail: PlanDetailType[]
}

export interface PriceType {
  id: number
  name: string
  planPrice: SubscriptionPlan[]
}
export interface SubscriptionType {
  prices: PriceType[]
  benefitType?: string
  benefitInfo?: {
    affiliateDescription?: string
    affiliateImage?: string
    daysLeft?: string
    hoursLeft?: string
    minutesLeft?: string
    secondsLeft?: string
    inviteCode?: string
    bonusRate?: number
    couponDescription?: string
    until?: string
  }
}

export interface SubscriptionPlan {
  id: number
  subscriptionType: (typeof subscriptionType)[keyof typeof subscriptionType]
  originPrice: number
  price: number
  description: string
  descriptionPrice: number
  subscriptionTypeLabel: string
  monthlyPrice: number
  bonusPoint?: number
}
export async function getPlans(): Promise<Plan[]> {
  try {
    return await API.get('/plans', {})
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getFreePlan(): Promise<Plan> {
  try {
    return await API.get('/plans/free', {})
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getPlansPrice(
  subscriptionType: string
): Promise<SubscriptionType> {
  try {
    return await API.get(`/plans/v2/price/${subscriptionType}`, {})
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getUsageMonthly(): Promise<UsageListType> {
  try {
    return await API.get('/fast-cut/usage-request-monthly', {})
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getUsageAnnual(): Promise<UsageListType> {
  try {
    return await API.get('/fast-cut/usage-request-annual', {})
  } catch (error) {
    // 오류 처리
    throw error
  }
}
