import { useQuery } from '@tanstack/react-query'
import { getAIPromptType, getVoice } from '../../service/list'

function useGetPrompt() {
  return useQuery({
    queryKey: ['get-ai-prompt-type'],
    queryFn: () => getAIPromptType(),
    staleTime: 6 * 1000,
  })
}

export default useGetPrompt
