import { UseQueryResult, useMutation, useQuery } from '@tanstack/react-query'

import { createChallenge, insertLink } from '../../../service/challenge'

function useInsertLinkChallenge() {
  return useMutation({
    mutationKey: ['insert-link'],
    mutationFn: insertLink,
  })
}

export default useInsertLinkChallenge
