import React, { Dispatch, PropsWithChildren, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'
interface Props {
  couponTooltipStatus: boolean
  setCouponTooltipStatus: Dispatch<SetStateAction<boolean>>
}

interface MissionProps {
  secretCouponTooltipStatus: boolean
  setSecretCouponTooltipStatus: Dispatch<SetStateAction<boolean>>
}

export const CouponToolitip = ({
  children,
  couponTooltipStatus,
  setCouponTooltipStatus,
}: PropsWithChildren<Props>) => {
  return (
    <>
      {' '}
      {couponTooltipStatus && (
        <div
          className="flex flex-col p-[16px] md:p-5 gap-[10px] bg-white  w-[330px] 
        h-auto absolute border-[3px] border-[#D9DEE8]  rounded-[20px] top-7 right-0 z-50"
        >
          <p className="font-bold text-[16px] flex justify-between items-center">
            미션 & 럭키드로우 안내
            <button
              onClick={() => {
                setCouponTooltipStatus(false)
              }}
            >
              <img
                src="assets/img/Icon_x.png"
                alt="icon_x"
                className="md:w-[40px] md:h-[40px] w-[25px] h-[25px]"
              />
            </button>
          </p>
          <p className="  text-[14px] w-full text-center ">
            프로/프리미엄 연간 결제 플랜 사용시 <br />매 라운드 영상 생성 80개를
            달성하시면
            <br /> 선물을 뽑을 수 있어요! <br /> 선물의 종류는 계속 변경됩니다
            🎁
          </p>
          <div className="flex flex-col w-full gap-2 items-center py-4 px-[10px] bg-[#F6F8FB]">
            <div className="flex gap-2">
              {' '}
              <img
                src="assets/img/coupon/10000.png"
                width={62.5}
                height={36}
                alt=""
              />
              <img
                src="assets/img/coupon/5000.png"
                width={62.5}
                height={36}
                alt=""
              />
              <img
                src="assets/img/coupon/3000.png"
                width={62.5}
                height={36}
                alt=""
              />
            </div>
            <div className="flex gap-2">
              {' '}
              <img
                src="assets/img/coupon/1000.png"
                width={62.5}
                height={36}
                alt=""
              />
              <img
                src="assets/img/coupon/100.png"
                width={62.5}
                height={36}
                alt=""
              />
            </div>
          </div>
          {/* <div>
            <ul className=" list-disc text-[12px] text-[#8F929B] flex flex-col justify-center w-full items-center">
              <li>캐시 관리 화면에서 적립 현황을 확인할 수 있습니다.</li>
              <li>지급된 캐시는 1개월 동안 사용 가능합니다.</li>
            </ul>
          </div> */}
        </div>
      )}
    </>
  )
}

export const CouponToolitip2 = ({
  children,
  couponTooltipStatus,
  setCouponTooltipStatus,
}: PropsWithChildren<Props>) => {
  return (
    <>
      {' '}
      {couponTooltipStatus && (
        <div
          className="flex flex-col p-[16px] md:p-5 gap-[10px] bg-white w-[330px] 
        h-auto absolute border-[3px] border-[#D9DEE8]  rounded-[20px] top-7 right-0"
        >
          <p className="font-bold text-[16px] flex justify-between items-center">
            꽝 없는 럭키드로우
            <button
              onClick={() => {
                setCouponTooltipStatus(false)
              }}
            >
              <img
                src="assets/img/Icon_x.png"
                alt="icon_x"
                className="md:w-[40px] md:h-[40px] w-[25px] h-[25px]"
              />
            </button>
          </p>
          <p className="  text-[14px] w-full text-center ">
            각 라운드마다 영상 생성 100%를 달성하면 <br />
            선물을 뽑을 수 있어요! <br />
            선물의 종류는 계속 변경됩니다 🎁
          </p>
          <div className="flex flex-col w-full gap-2 items-center py-4 px-[10px] bg-[#F6F8FB]">
            <div className="flex gap-2">
              {' '}
              <img
                src="assets/img/coupon/10000.png"
                width={62.5}
                height={36}
                alt=""
              />
              <img
                src="assets/img/coupon/5000.png"
                width={62.5}
                height={36}
                alt=""
              />
              <img
                src="assets/img/coupon/3000.png"
                width={62.5}
                height={36}
                alt=""
              />
            </div>
            <div className="flex gap-2">
              {' '}
              <img
                src="assets/img/coupon/1000.png"
                width={62.5}
                height={36}
                alt=""
              />
              <img
                src="assets/img/coupon/100.png"
                width={62.5}
                height={36}
                alt=""
              />
            </div>
          </div>
          <div>
            <ul className=" list-disc text-[12px] text-[#8F929B] flex flex-col justify-center w-full items-center">
              <li>라운드 기간 중 미션 달성 완료해야 뽑을 수 있습니다.</li>
              <li>캐시 관리 화면에서 적립 현황을 확인할 수 있습니다.</li>
              <li>지급된 캐시는 1개월 동안 사용 가능합니다.</li>
            </ul>
          </div>
        </div>
      )}
    </>
  )
}

export const PercentyCouponToolitip = ({
  children,
  couponTooltipStatus,
  setCouponTooltipStatus,
}: PropsWithChildren<Props>) => {
  return (
    <>
      {' '}
      {couponTooltipStatus && (
        <div
          className="flex flex-col p-[16px] md:p-5 gap-[10px] bg-white w-[330px] 
        h-auto absolute border-[3px] border-[#D9DEE8]  rounded-[20px] top-7 right-0"
        >
          <p className="font-bold text-[16px] flex justify-between items-center">
            다음 달 결제 보너스 혜택
            <button
              onClick={() => {
                setCouponTooltipStatus(false)
              }}
            >
              <img
                src="assets/img/Icon_x.png"
                alt="icon_x"
                className="md:w-[40px] md:h-[40px] w-[25px] h-[25px]"
              />
            </button>
          </p>
          <p className="  text-[14px] w-full text-center ">
            이번 달 영상 생성 80개를 달성 하면 <br />
            다음 월 결제 플랜 결제시 할인 혜택을 드려요
          </p>
          <div className="flex justify-center">
            <div className="flex flex-col w-[200px] gap-2 items-center py-4 px-[10px] bg-[#F6F8FB]">
              <div className="flex gap-2">
                {' '}
                <img
                  src="assets/img/coupon/20percenty.png"
                  width={123.32}
                  height={71}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div>
            <ul className=" list-disc text-[12px] text-[#8F929B] flex flex-col justify-center w-full items-center">
              <li>월 결제 플랜에 사용 가능합니다.</li>
              <li>할인 혜택은 체험 기간 동안 적용 가능합니다.</li>
            </ul>
          </div>
        </div>
      )}
    </>
  )
}

export const FreeCouponToolitip = ({
  children,
  couponTooltipStatus,
  setCouponTooltipStatus,
}: PropsWithChildren<Props>) => {
  return (
    <>
      {' '}
      {couponTooltipStatus && (
        <div
          className="flex flex-col p-[16px] md:p-5 gap-[10px] bg-white w-[330px] 
        h-auto absolute border-[3px] border-[#D9DEE8]  rounded-[20px] top-7 right-0"
        >
          <p className="font-bold text-[16px] flex justify-between items-center">
            시크릿 체험 미션 쿠폰
            <button
              onClick={() => {
                setCouponTooltipStatus(false)
              }}
            >
              <img
                src="assets/img/Icon_x.png"
                alt="icon_x"
                className="md:w-[40px] md:h-[40px] w-[25px] h-[25px]"
              />
            </button>
          </p>
          <p className="  text-[14px] w-full text-center ">
            🤫 쉿! 시크릿 체험 미션을 드렸어요!
            <br />
            무료 체험 기간 동안 영상 생성 100% 달성하면
            <br />
            50% 할인 쿠폰을 받으실 수 있어요
          </p>
          <div className="flex justify-center">
            <div className="flex flex-col w-[200px] gap-2 items-center py-4 px-[10px] bg-[#F6F8FB]">
              <div className="flex gap-2">
                {' '}
                <img
                  src="assets/img/coupon/50.png"
                  width={123.32}
                  height={71}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div>
            <ul className=" list-disc text-[12px] text-[#8F929B] flex flex-col justify-center w-full items-center">
              <li>프로/프리미엄 연결제 플랜에 사용 가능합니다.</li>
              <li>할인 혜택은 체험 기간 동안 적용 가능합니다.</li>
            </ul>
          </div>
        </div>
      )}
    </>
  )
}

export const MissionTooltip = ({
  children,
  couponTooltipStatus,
  setCouponTooltipStatus,
}: PropsWithChildren<Props>) => {
  const navigate = useNavigate()
  return (
    <>
      {' '}
      {couponTooltipStatus && (
        <>
          <div
            className="flex flex-col  absolute 
     top-7 right-0"
          >
            {/* <p className="font-bold text-[16px] flex justify-between items-center">
            시크릿 체험 미션 쿠폰
            <button
              onClick={() => {
                setCouponTooltipStatus(false)
              }}
            >
              <img
                src="assets/img/Icon_x.png"
                alt="icon_x"
                className="md:w-[40px] md:h-[40px] w-[25px] h-[25px]"
              />
            </button>
          </p> */}
            <div className="flex flex-col  gap-[10px] bg-white md:w-[400px] w-[335px]    h-auto     rounded-t-[40px] py-8 ">
              <p className="text-[18px] font-bold w-full text-center">
                선착순 참여 성공!
              </p>
              <p className="  text-[14px] w-full text-center ">
                🤫 쉿! 시크릿 체험 미션을 드렸어요!
                <br />
                무료 체험 기간 동안 영상 생성 100% 달성하면
                <br />
                50% 할인 쿠폰을 받으실 수 있어요
                <br />
              </p>
              <div className="flex justify-center">
                <div className="flex flex-col w-[200px] gap-2 items-center py-4 px-[10px] bg-[#F6F8FB]">
                  <div className="flex gap-2">
                    {' '}
                    <img
                      src="assets/img/coupon/50.png"
                      width={123.32}
                      height={71}
                      alt=""
                    />
                  </div>
                </div>
              </div>{' '}
            </div>
            <div
              className="bg-main  rounded-b-[40px] md:w-[400px] w-[335px] h-[60px] 
            flex justify-center font-bold text-white text-[14px] items-center cursor-pointer"
              onClick={() => {
                navigate('/my-videos')
              }}
            >
              <p>미션 시작</p>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export const SecretMissionTooltip = ({
  children,
  secretCouponTooltipStatus,
  setSecretCouponTooltipStatus,
}: PropsWithChildren<MissionProps>) => {
  const navigate = useNavigate()
  return (
    <>
      {' '}
      {secretCouponTooltipStatus && (
        <>
          <div
            className="flex flex-col  absolute 
     top-7 right-[30%]"
          >
            {/* <p className="font-bold text-[16px] flex justify-between items-center">
            시크릿 체험 미션 쿠폰
            <button
              onClick={() => {
                setCouponTooltipStatus(false)
              }}
            >
              <img
                src="assets/img/Icon_x.png"
                alt="icon_x"
                className="md:w-[40px] md:h-[40px] w-[25px] h-[25px]"
              />
            </button>
          </p> */}
            <div className="flex flex-col  gap-[10px] bg-white md:w-[440px] w-[335px] px-5   h-auto     rounded-t-[40px] py-8 ">
              <p className="text-[18px] font-bold w-full text-center">
                <span className="text-[#4360EC]">
                  🙌 무료 체험 + 시크릿 미션 시작!
                </span>
                <br />
                아래 내용을 꼭 기억해주세요
              </p>
              <div className="flex flex-col bg-[#F6F8FB] p-[20px] items-start justify-center text-left  rounded-[20px]">
                <p className="font-bold">
                  {' '}
                  1. 쿠팡 파트너스나 알리 익스프레스 어필리에이트 계정
                  준비하셨나요?
                </p>
                <div className="flex flex-col bg-white w-full justify-center  mt-1  p-[7px]">
                  {' '}
                  <p className="flex gap-2 md:text-base text-[10px] ">
                    <img
                      src="assets/img/coupang_icon.png"
                      alt="coupang"
                      width={24}
                      height={24}
                      className="md:w-[24px] md:h-[24px] w-[16px] h-[16px]"
                    />
                    <a
                      href="https://solution.fastseller.shop/15cc8c5d-bcd4-4d50-b145-4f32bad40634"
                      target="_blank"
                      rel="noreferrer"
                      className="md:text-base text-[10px] text-href"
                    >
                      {' '}
                      {`쿠팡 파트너스 가이드 >`}
                    </a>
                  </p>
                  <p className="flex gap-2 md:text-base text-[10px] items-center mt-[8px]">
                    <img
                      src="assets/img/ali_icon.png"
                      alt="ali"
                      className="md:w-[24px] md:h-[24px] w-[16px] h-[16px] "
                    />
                    <a
                      href="https://solution.fastseller.shop/2ad66cae-7290-42eb-a3f4-83fcf3fc15c8"
                      target="_blank"
                      rel="noreferrer"
                      className="text-href"
                    >{`알리익스프레스 어필리에이트 가이드 >`}</a>
                  </p>
                </div>

                <p className="mt-[24px] font-bold">
                  2. '자동 업로드 설정' 페이지에서 유튜브 자동업로드에 필요한
                  설정을 해주세요.
                </p>
                <p className="mt-[24px] font-bold">
                  {' '}
                  3. 상품 링크 등의 정보를 넣고 영상 생성을 요청하세요.
                </p>
                <p className="mt-[24px] font-bold">
                  4. 50개 요청 완료 후 영상 생성/업로드가 시작됩니다.
                </p>
              </div>
            </div>
            <div
              className="bg-main  rounded-b-[40px] md:w-[440px] w-[335px] h-[60px] 
            flex justify-center font-bold text-white text-[14px] items-center cursor-pointer"
              onClick={() => {
                navigate('/video-editor')
              }}
            >
              <p>영상 생성 시작</p>
            </div>
          </div>
        </>
      )}
    </>
  )
}
