import { useQuery } from '@tanstack/react-query'
import { getAIToneLongType, getAIToneShortType } from '../../service/list'

export function useGetLongTypeTone(type: string) {
  return useQuery({
    queryKey: ['get-ai-tone-type-long'],
    queryFn: () => getAIToneLongType(type),
    staleTime: 6 * 1000,
  })
}

export function useGetShortTypeTone(type: string) {
  return useQuery({
    queryKey: ['get-ai-tone-type-short'],
    queryFn: () => getAIToneShortType(type),
    staleTime: 6 * 1000,
  })
}
