import { useEffect, useState } from 'react'
import { Input } from '../components/atom/Input'
import { useNavigate } from 'react-router-dom'
import { WelcomeBox } from '../components/freePlan/WelcomeBox'
import { signUpBtnClick } from '../utils/metaAd'
import { getLocalStorage } from '../utils/common'
import useGetAffiliateProgramByCode from '../hooks/query/affiliate/useGetAffiliateProgramByCode'
import usePrevStore from '../store/usePrevStore'

const SignUp = () => {
  const { mutate } = useGetAffiliateProgramByCode()
  const [isCheck, setCheck] = useState<boolean>(false)
  const [isMarketing, setMarketing] = useState<boolean>(false)
  const [isAllCheck, setAllCheck] = useState<boolean>(false)
  const [isAffiliate, setAffiliate] = useState<boolean>(false)
  const [affiliateImg, setAffiliateImg] = useState<string>('')
  const navigate = useNavigate()
  const affiliate_source = getLocalStorage('affiliate_source')
  const affiliate_campaign = getLocalStorage('affiliate_campaign')
  const invite_code = getLocalStorage('invite_code')
  const prevUrl = usePrevStore((state: any) => state.prevUrl)
  const onSignUp = () => {
    const utm_source = getLocalStorage('utm_source')
    const utm_campaign = getLocalStorage('utm_campaign')

    const state = JSON.stringify({
      terms: 'signUp',
      marketing: isMarketing ? 'true' : 'false',
      utm_source: utm_source ? utm_source : '',
      utm_campaign: utm_campaign ? utm_campaign : '',
      affiliate_source: affiliate_source ?? '',
      affiliate_campaign: affiliate_campaign ?? '',
      prev_pathname: prevUrl ?? '/',
      invite_code: invite_code ?? '',
    })

    window.location.href = `${process.env.REACT_APP_API_URL}/auth/google?state=${state}`
  }

  const handleAllCheck = () => {
    const shouldCheck = !isMarketing || !isCheck

    setMarketing(shouldCheck)
    setCheck(shouldCheck)
  }

  useEffect(() => {
    if (affiliate_campaign) {
      mutate(affiliate_campaign, {
        onSuccess: (data) => {
          setAffiliate(true)
          setAffiliateImg(data?.link)
        },
        onError: () => {},
      })
    }
  }, [])
  return (
    <>
      <div className="pt-[60px] max-w-[816px] h-min-[100vh] my-0 mx-auto flex flex-col items-center gap-[40px]">
        <div>
          <p className="text-[36px] md:text-[48px] font-bold flex items-center justify-center">
            <button
              onClick={() => {
                navigate('/sign-up')
              }}
            >
              {' '}
              <span>회원가입</span>{' '}
            </button>
            <span className="text-[#B6BBC6] mx-[20px] md:mx-[40px]">|</span>{' '}
            <button
              onClick={() => {
                navigate('/sign-in')
              }}
            >
              {' '}
              <span className="text-[#B6BBC6]">로그인</span>
            </button>
          </p>
        </div>

        {isAffiliate ? (
          <>
            {/* 협업 업체가 있는 경우 보여주는 화면  */}
            <div>
              <img
                src={affiliateImg}
                alt="icon"
                className="md:w-[520px] w-[335px]"
              />
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col items-center">
              <h1 className="font-bold text-[26px] md:text-[32px]">
                클릭 한 번에
              </h1>
              <div className="flex items-center gap-1 mb-2">
                <h1 className="font-bold text-[26px] md:text-[32px]">
                  상품 소개 영상
                </h1>
                <h1 className="font-bold text-[26px] md:text-[32px]">
                  유튜브 등록!
                </h1>
              </div>
              <div className="flex justify-center items-center gap-[4px] text-[14px] md:text-[18px]">
                <img
                  src="assets/img/coupang_icon.png"
                  alt="coupang"
                  width={17.76}
                  height={17.42}
                />
                <img
                  src="assets/img/ali_icon.png"
                  alt="ali"
                  width={17.76}
                  height={17.42}
                />

                <p>어필리에이트 링크만 있으면</p>
              </div>

              <div className="flex justify-center text-[10px] md:text-[18px]">
                <p className="text-main font-bold">FASTCUT AI</p>
                <p>
                  가 나레이션, 편집, BGM 넣은 영상을 내 채널에 등록까지 해줘요
                </p>
              </div>
            </div>
          </>
        )}
        <div className="w-[335px] md:w-[521px] flex flex-col items-center mt-[20px] gap-[20px]">
          <div className="w-[335px] h-[64px]  flex items-center justify-center ">
            <button
              className={`text-[18px] flex justify-between border border-[#D9DEE8] rounded-[10px] bg-[#FFFFFF] text-[#222222] ${
                !isCheck && 'opacity-[20%]'
              }`}
              onClick={onSignUp}
              disabled={!isCheck}
            >
              <div className="w-[66px] h-[64px] border-r  flex items-center justify-center">
                <img
                  src="assets/img/logo_google.png"
                  alt="google_log"
                  width={24}
                  height={24}
                />
              </div>
              <div className="w-[271px] md:w-[456px] h-[64px] flex items-center justify-center">
                <span>구글 계정으로 계속하기</span>
              </div>
            </button>
          </div>
          <div className="flex  items-center gap-3 text-[18px] justify-start w-full">
            <input
              type="checkbox"
              className="w-[20px] h-[20px]"
              onClick={handleAllCheck}
              checked={isCheck && isMarketing}
            />
            <p className="text-[#8F929B]">전체 동의</p>
          </div>
          <div className="flex flex-col  gap-[10px] ml-6">
            <div className="flex items-center text-[18px] gap-2 ">
              {' '}
              <div>
                <input
                  type="checkbox"
                  className="w-[20px] h-[20px]"
                  onClick={() => {
                    setCheck(!isCheck)
                  }}
                  checked={isCheck}
                />
              </div>
              <p className="text-[#8F929B]">
                [필수] 패스트컷
                <a
                  href="https://solution.fastseller.shop/termofuse"
                  target="_blank"
                  className="ml-[4px] text-[#4360EC] font-medium"
                  rel="noreferrer"
                >
                  이용약관,
                </a>
                <a
                  href="https://solution.fastseller.shop/privacypolicy"
                  target="_blank"
                  className="m-[4px] text-[#4360EC] font-medium"
                  rel="noreferrer"
                >
                  개인정보 처리방침,
                </a>
                <a
                  href="https://solution.fastseller.shop/privacypolicy"
                  target="_blank"
                  className="m-[4px] text-[#4360EC] font-medium"
                  rel="noreferrer"
                >
                  패스트컷 캐시정책,
                </a>
                <a
                  href="https://solution.fastseller.shop/privacypolicy"
                  target="_blank"
                  className="m-[4px] text-[#4360EC] font-medium"
                  rel="noreferrer"
                >
                  패스트컷 마케팅 제휴 프로그램 정책
                </a>
                동의
              </p>
            </div>
            <div className="flex  items-center gap-3 text-[18px]">
              <input
                type="checkbox"
                className="w-[20px] h-[20px]"
                onClick={() => {
                  setMarketing((prev) => !prev)
                }}
                checked={isMarketing}
              />
              <p className="text-[#8F929B]">
                [선택] 패스트컷
                <a
                  href="https://angelleague.notion.site/517e947b6f094d93ad6ae1911955bd29?pvs=4"
                  target="_blank"
                  className="ml-[4px] text-[#4360EC] font-medium"
                  rel="noreferrer"
                >
                  마켓팅 정보 수신 동의
                </a>
              </p>
            </div>
          </div>
          <WelcomeBox />
        </div>
      </div>
    </>
  )
}

export default SignUp
