import React from 'react'
import { useNavigate } from 'react-router-dom'

export const PaymentFail = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const path = urlParams.get('path')
  const handleBack = () => {
    navigate(`/${path}`)
  }
  const navigate = useNavigate()
  return (
    <div className=" relative md:pt-[60px] pt-[24px] md:max-w-[816px] md:min-h-[100vh] max-w-[375px] my-0 mx-auto flex flex-col px-[16px]">
      <div className="flex justify-center font-bold text-[48px]">
        <p>플랜 결제</p>
      </div>

      <div className="flex flex-col items-center font-bold text-[18px] ">
        <span className="text-[68px] mb-[32px]">🙏</span>
        <p className="mb-[8px]">결제에 실패했어요</p>
        <p className="mb-[8px]">다시 시도해주세요!</p>
        <p className="text-main ">
          <button onClick={handleBack}>이전 화면으로 돌아가기 {`>`}</button>
        </p>
      </div>
    </div>
  )
}
