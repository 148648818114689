import { useQuery } from '@tanstack/react-query'
import { getBgm, getThumbnail } from '../../service/list'

function useGetThumbnail() {
  return useQuery({
    queryKey: ['get-thumbnails'],
    queryFn: () => getThumbnail(),
    staleTime: 6 * 1000,
  })
}

export default useGetThumbnail
