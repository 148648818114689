import classNames from 'classnames'
import { PropsWithChildren, useEffect, useState } from 'react'

interface PlanTagProps {
  planName: string
  className: string
}

export const PlanTag = ({
  children,
  planName,
  className,
}: PropsWithChildren<PlanTagProps>) => {
  const inputClass = classNames(
    `text-[8px] text-white  bg-black px-[2px] py-[1px] h-[11px] align-middle flex items-center rounded-[2px]  ${className}`
  )
  const selectBg = (planName: string) => {
    if (planName === 'LIGHT') {
      return 'rgba(96, 184, 251, 1),rgba(11, 123, 209, 1),rgba(156, 224, 255, 1)'
    } else if (planName === 'PREMIUM') {
      return 'rgba(0, 128, 130, 1),rgba(0, 94, 94, 1),rgba(29, 197, 194, 1)'
    } else if (planName === 'PRO') {
      return 'rgba(96, 16, 157, 1),rgba(14, 0, 94, 1),rgba(190, 73, 198, 1)'
    }
  }
  return (
    <>
      <p
        className={inputClass}
        style={{
          background: `linear-gradient(to right, ${selectBg(planName)})`,
          fontFamily: 'Russo One',
        }}
      >
        {planName}
      </p>
    </>
  )
}
