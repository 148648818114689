import { useQuery } from '@tanstack/react-query'
import { getAnimationList, getBgm } from '../../service/list'

function useGetAnimation() {
  return useQuery({
    queryKey: ['get-animation-list'],
    queryFn: () => getAnimationList(),
    staleTime: 6 * 1000,
  })
}

export default useGetAnimation
