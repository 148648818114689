import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { createVideo } from '../../service/list'
import { postExperiencePlan } from '../../service/payment'

export const usePostExperiencePlan = () => {
  return useMutation({
    mutationKey: ['experience-plan'],
    mutationFn: postExperiencePlan,
  })
}
