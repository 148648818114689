import { create } from 'zustand'
import { FastCutProfile } from '../service/user'

// 공동 사용되는 전역 변수 저장

const useUserStore = create((set) => ({
  userInfo: null,
  isLoading: true,
  refetch: null,
  set: (payload: any) => set(() => payload),
  setLoading: (loading: boolean) => set({ isLoading: loading }),
  setRefetch: (refetch: any) => set({ refetch }),
  destroy: () => set({}, true),
}))

export default useUserStore
