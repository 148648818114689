import { useQuery } from '@tanstack/react-query'

import { getChallenge } from '../../../service/challenge'

function useGetChallenge() {
  return useQuery({
    queryKey: ['get-challenge-list'],
    queryFn: () => getChallenge(),
    staleTime: 6 * 1000,
  })
}

export default useGetChallenge
