import { useQuery } from '@tanstack/react-query'

import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useDeferredValue,
  useEffect,
  useRef,
  useState,
} from 'react'
import { UserInfoType } from '../service/user'
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { BackgroundType, TemplateItem } from '../service/list'
import { CircularCheckbox } from './CircularCheckbox'
import { isMobile } from 'react-device-detect'

interface ModalProps {
  setMixTypeModal: Dispatch<SetStateAction<boolean>>
  isMixTypeModal: boolean
  userInfo: UserInfoType
  bgList: BackgroundType[] | undefined
  watch: UseFormWatch<{
    link: string
    mainTitle: string
    animationId: number
    voiceId: number
    bgmId: number
    backgroundId: number
    templateId: number
    templateType: string
    aiToneTypeId: number
    aiPromptDurationId: number
    subtitleId: number
  }>
  control: Control<
    {
      link: string
      mainTitle: string
      animationId: number
      voiceId: number
      bgmId: number
      backgroundId: number
      templateId: number
      templateType: string
      aiToneTypeId: number
      aiPromptDurationId: number
      subtitleId: number
    },
    any
  >
  setValue: UseFormSetValue<{
    link: string
    mainTitle: string
    animationId: number
    voiceId: number
    bgmId: number
    backgroundId: number
    templateId: number
    templateType: string
    aiToneTypeId: number
    aiPromptDurationId: number
    subtitleId: number
  }>
  mixList:
    | {
        list: TemplateItem[]
        cash: string
        originCash: number
      }
    | undefined
}

export const MixTypeModal = ({
  children,
  setMixTypeModal,
  isMixTypeModal,
  userInfo,
  setValue,
  watch,
  mixList,
  bgList,
  control,
}: PropsWithChildren<ModalProps>) => {
  if (!mixList || !bgList) <></>

  const [templateId, setTemplateId] = useState(watch('templateId'))
  const [backgroundId, setBackgroundId] = useState(watch('backgroundId'))
  const [mix, setMix] = useState(mixList?.list.find((el) => el.cashRate >= 1))
  const [bg, setBg] = useState(bgList?.find((el) => el.cashRate >= 1))
  const handleSaveBtn = () => {
    const info = mixList?.list?.filter((el) => el.id === templateId)
    if (backgroundId !== 0 && templateId !== 0 && info?.length) {
      setValue('templateType', 'mix')
      setValue('backgroundId', backgroundId)
      setValue('templateId', templateId)

      setMixTypeModal((prev) => !prev)
    }
  }

  return (
    <div className="h-screen w-full fixed left-0 top-0 flex flex-col justify-center items-center bg-black bg-opacity-50 text-center  z-50">
      <div className="bg-white w-[375px] md:w-[816px] md:h-[700px] h-[100vh] px-[20px] md:rounded-[40px] border overflow-auto pb-[150px]">
        <div className="sticky top-0 bg-white z-10   md:px-10 pt-10  ">
          <p className="flex justify-between items-center">
            <p className="font-bold text-[24px]">믹스 타입</p>
            <button
              onClick={() => {
                setMixTypeModal(false)
              }}
            >
              <img
                src="assets/img/Icon_x.png"
                alt="icon_x"
                height={40}
                width={40}
              />
            </button>
          </p>
        </div>
        <div className="md:px-10">
          <div className="mt-4">
            <p className="text-left">
              이미지와 제목의 화면 구성 형태를 고른 후, 그 뒤에 깔리면 어울릴 만
              한 배경 영상을 골라주세요!
              <br />
              정사각형 이미지에 가장 적합합니다.
            </p>
          </div>
          <p className="text-[14px] text-error text-left mt-4">할인 이벤트중</p>
          {isMobile ? (
            <div className="flex flex-col mt-10 bg-[#F6F8FB] w-full p-5">
              <div className="flex items-center gap-4 ">
                <p className="font-bold">이미지 프레임 + 제목</p>

                <p className="flex items-center gap-1">
                  {' '}
                  <img
                    src="assets/img/cash.png"
                    alt="cash"
                    className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
                  />
                  <s className="text-[#5E6068]  ml-1">{`${mix?.price}`}캐시</s>
                  <span className="text-main">{`${mix?.originPrice}`}캐시</span>
                </p>
              </div>{' '}
              <p className="text-[14px] text-main text-left">
                컨텐츠 이미지 비율에 맞는 레이아웃을 골라보세요!
              </p>
              <div className="overflow-x-auto">
                <div className="inline-flex gap-x-[4px] gap-y-[16px] mt-4">
                  {mixList?.list?.map((template, index) => {
                    return (
                      <div
                        className="min-w-[136px] flex justify-between md:items-center"
                        key={index}
                      >
                        <div className="flex flex-col h-full gap-2">
                          <img
                            src={template.path}
                            alt="bg"
                            width={140}
                            height={277}
                            loading={'lazy'}
                          />
                          <div className="flex">
                            <Controller
                              render={({ field }) => (
                                <CircularCheckbox
                                  {...field}
                                  id={index}
                                  checked={templateId === template.id}
                                  disabled={!userInfo}
                                  onChange={() => setTemplateId(template.id)}
                                  label={template.name}
                                />
                              )}
                              name="templateId"
                              control={control}
                            />

                            {new Array(template.cashRate)
                              .fill(0)
                              .map((el, idx) => (
                                <img
                                  src="assets/img/cash.png"
                                  alt="cash"
                                  key={idx}
                                  className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
                                />
                              ))}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col mt-5">
              <div className="flex items-center gap-4 ">
                <p className="font-bold">이미지 프레임 + 제목</p>

                <p className="flex items-center gap-1">
                  {' '}
                  <img
                    src="assets/img/cash.png"
                    alt="cash"
                    className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
                  />
                  <s className="text-[#5E6068]  ml-1">{`${mix?.price}`}캐시</s>
                  <span className="text-main">{`${mix?.originPrice}`}캐시</span>
                </p>
              </div>{' '}
              <p className="text-[14px] text-main text-left">
                컨텐츠 이미지 비율에 맞는 레이아웃을 골라보세요!
              </p>
              <div className="grid grid-cols-2 md:grid-cols-5 gap-x-[10px] gap-y-[16px] mt-4">
                {mixList?.list?.map((template, index) => {
                  return (
                    <>
                      <div
                        className="w-full flex justify-between md:items-center"
                        key={index}
                      >
                        <div className="flex flex-col h-full gap-2">
                          <img
                            src={template.path}
                            alt="bg"
                            width={140}
                            height={277}
                            loading={'lazy'}
                          />
                          <div className="flex">
                            <Controller
                              render={({ field }) => (
                                <CircularCheckbox
                                  {...field}
                                  id={index}
                                  checked={templateId === template.id}
                                  disabled={!userInfo}
                                  onChange={() => setTemplateId(template.id)}
                                  label={template.name}
                                />
                              )}
                              name="templateId"
                              control={control}
                            />

                            {new Array(template.cashRate).fill(0).map((el) => (
                              <>
                                {' '}
                                <img
                                  src="assets/img/cash.png"
                                  alt="cash"
                                  className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
                                />
                              </>
                            ))}
                          </div>
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
          )}

          {isMobile ? (
            <div className="flex flex-col mt-10 bg-[#F6F8FB] w-full p-5">
              <div className="flex items-center gap-4 ">
                <p className=" text-[18px] mb-[16px] flex items-center gap-1 relative">
                  <span className="font-bold mr-4">배경 영상</span>
                  <img
                    src="assets/img/cash.png"
                    alt="cash"
                    className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
                  />
                  <s className="text-[#5E6068]  ml-1">{`${bg?.price}`}캐시</s>
                  <span className="text-main">{`${bg?.originPrice}`}캐시</span>
                </p>
              </div>{' '}
              <div className="overflow-x-auto">
                <div className="inline-flex gap-x-[4px] gap-y-[16px] mt-4">
                  {bgList?.map((bg, index) => {
                    return (
                      <div
                        className="min-w-[136px] flex justify-between md:items-center"
                        key={index}
                      >
                        <div className="flex flex-col h-full gap-2">
                          <img
                            src={bg.path}
                            alt="bg"
                            width={140}
                            height={277}
                            loading={'lazy'}
                          />
                          <div className="flex">
                            <Controller
                              render={({ field }) => (
                                <CircularCheckbox
                                  {...field}
                                  id={index}
                                  checked={backgroundId === bg.id}
                                  disabled={!userInfo}
                                  onChange={() => setBackgroundId(bg.id)}
                                  label={bg.name}
                                />
                              )}
                              name="templateId"
                              control={control}
                            />
                            {new Array(bg.cashRate).fill(0).map((el, idx) => (
                              <img
                                src="assets/img/cash.png"
                                alt="cash"
                                key={idx}
                                className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          ) : (
            <div className="background mt-9">
              <p className=" text-[18px] mb-[16px] flex items-center gap-1 relative">
                <span className="font-bold mr-4">배경 영상</span>
                <img
                  src="assets/img/cash.png"
                  alt="cash"
                  className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
                />
                <s className="text-[#5E6068]  ml-1">{`${bg?.price}`}캐시</s>
                <span className="text-main">{`${bg?.originPrice}`}캐시</span>
              </p>

              <div className="grid grid-cols-2 md:grid-cols-5 gap-x-[10px] gap-y-[16px]">
                {bgList?.map((bg, index) => {
                  return (
                    <>
                      <div
                        className="w-full flex justify-between md:items-center"
                        key={index}
                      >
                        <div className="flex flex-col h-full gap-2">
                          <img
                            src={bg.path}
                            alt="bg"
                            width={140}
                            height={277}
                            loading={'lazy'}
                          />
                          <div className="flex">
                            <Controller
                              render={({ field }) => (
                                <CircularCheckbox
                                  {...field}
                                  id={index}
                                  checked={backgroundId === bg.id}
                                  disabled={!userInfo}
                                  onChange={() => setBackgroundId(bg.id)}
                                  label={bg.name}
                                />
                              )}
                              name="backgroundId"
                              control={control}
                            />
                            {new Array(bg.cashRate).fill(0).map((el) => (
                              <>
                                {' '}
                                <img
                                  src="assets/img/cash.png"
                                  alt="cash"
                                  className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
                                />
                              </>
                            ))}
                          </div>
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
          )}

          <div className="mt-10">
            <button
              className="w-full text-white py-[19px] text-[18px] font-bold bg-main rounded-[40px]"
              onClick={handleSaveBtn}
            >
              저장
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
