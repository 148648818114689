import { useQuery } from '@tanstack/react-query'
import { getBgm, getShortVideoTemplate } from '../../service/list'

function useGetTemplate() {
  return useQuery({
    queryKey: ['get-template'],
    queryFn: () => getShortVideoTemplate(),
    staleTime: 6 * 1000,
  })
}

export default useGetTemplate
