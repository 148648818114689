import { useQuery } from '@tanstack/react-query'
import {
  createInitialMyPointHistoryData,
  getMyPointHistory,
} from '../../../service/point'

export function useGetMyPointHistory(page: number, pageSize: number) {
  return useQuery({
    queryKey: ['get-my-point-history'],
    queryFn: () => getMyPointHistory(page, pageSize),
    refetchOnWindowFocus: true,
    retry: false,
    staleTime: 1000,
    initialData: createInitialMyPointHistoryData(),
  })
}
