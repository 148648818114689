import { UserInfo } from 'os'
import { create } from 'zustand'
import { FastCutProfile } from '../service/user'

// 공동 사용되는 전역 변수 저장

const useUsageStore = create((set) => ({
  usage: null,
  isLoading: true,
  usageRefetch: () => {},
  set: (payload: any) => set(() => payload),
  setLoading: (loading: boolean) => set({ isLoading: loading }),
  setRefetch: (refetch: any) => set({ usageRefetch: refetch }), // 수정된 부분
  destroy: () => set({}, true),
}))
export default useUsageStore
