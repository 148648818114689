import React from 'react'
interface CircularCheckboxProps {
  id: number
  label?: string
  checked?: boolean
  disabled?: boolean
  onChange: (id: number) => void
  className?: string
}
export const CircularCheckbox: React.FC<CircularCheckboxProps> = ({
  id,
  label,
  checked,
  disabled,
  onChange,
  className,
}) => {
  return (
    <>
      <div className="relative flex items-center gap-[8px]">
        <label className="relative flex items-center cursor-pointer">
          <input
            type="checkbox"
            checked={checked}
            onChange={() => onChange(id)}
            className="sr-only"
            disabled={disabled}
          />
          <div
            className={`w-[20px] h-[20px] rounded-full border-2 bg-main flex items-center justify-center ${
              checked ? 'bg-white border-main' : 'bg-white'
            }`}
          >
            {checked && <div className="w-3 h-3 bg-main rounded-full"></div>}
          </div>
        </label>
        <span
          className={`text-[#2E343D] md:text-[18px] ${className}`}
          style={!checked && disabled ? { opacity: 0.3 } : {}}
        >
          {label}
        </span>
      </div>
    </>
  )
}
