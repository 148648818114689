import { ChallengeStatusEnum } from '../utils/common'
import API from './api'
import { Plan, PlanDetailType, PriceType } from './plans'

export interface Usage {
  totalLimit: string
  usedCount: string
  available: boolean
  isDone: boolean
}

export interface UserInfoType {
  id: number
  email: string
  name: string
  termsAccepted: boolean
  youtubeAccepted: boolean
  youtubeAccount: YoutubeAccount
  youtubeChannel: YoutubeChannel[]
}
export interface UserAffiliatePoint {
  validUntil: string
  daysLeft: string
  hoursLeft: string
  minutesLeft: string
  secondsLeft: string
  affiliateCode: string
  affiliateDescription: string
  affiliateImage: string
  daysValid: number
  type: string
  name: string
  description: string
  point: number
  pointRate: string
  pointReasonType: string
  pointExpired: string
  pointExpiredDay: number
  planId: number
  planSubscriptionType: string
  planDiscountAmount: string
  planDiscountRate: string
}

export interface UserAffiliatePlan {
  validUntil: string
  daysLeft: string
  hoursLeft: string
  minutesLeft: string
  secondsLeft: string
  affiliateCode: string
  affiliateDescription: string
  affiliateImage: string
  daysValid: number
  type: string
  name: string
  description: string
  point: number
  pointRate: string
  pointReasonType: string
  pointExpired: string
  pointExpiredDay: string
  planId: number
  planSubscriptionType: string
  planDiscountAmount: string
  planDiscountRate: string
}

export interface FastCutShortUsageSummaryType {
  ready: string
  complete: string
}

export interface FastCutUsageSummaryType {
  ready: string
  complete: string
  upload: string
}

export interface Me {
  userType?: string
  subscriptions?: {
    planName: string
    userId: number
    planId: number
    startDate: string
    endDate: string
    subscriptionType: string
    daysLeft: number
  }
  challenge?: { id: number; status: ChallengeStatusEnum }
  userInfo?: UserInfoType
  alreadyBenefit?: any
  usage?: Usage
  inviteCode: string
  registInviteCode?: string
  experiencedUser: boolean
  fastCutUsageSummary: FastCutUsageSummaryType[]
  fastCutShortUsageSummary: FastCutShortUsageSummaryType[]
  invitePointRate: number
  affiliate: {
    point: UserAffiliatePoint
  }
  plan: UserAffiliatePlan[]
  couponBenefit?: {
    until: string
    daysLeft: number
    couponDescription: string
  }
}

export interface UserPlan {
  subscriptions: {
    planName: string
    userId: number
    planId: number
    startDate: string
    endDate: string
    subscriptionType: string
    daysLeft: number
    subscriptionTypeLabel: string
    currentMonth: string
    monthStart?: string
    monthEnd?: string
    round: number
  }
  usage: {
    totalLimit: string
    usedCount: string
    available: number
    isDone: boolean
  }
  planPrice: PriceType
  planDetail: Plan
}

export interface FastCutUser {
  id: number
  createdAt: string
  updatedAt: string
  deletedAt: string
  profileId: string
  etc: string
  email: string
  name: string
  sns: string
  termsAccepted: boolean
  youtubeAccepted: boolean
  youtubeChannelName: string
  youtubeChannelUrl: string
}

interface YoutubeChannel {
  channelId: string
  youtubeAuthorityFlag: boolean
  advancedFlag: boolean
  autoUploadFlag: boolean
}
interface YoutubeAccount {
  id: number
  youtubeChannelId: string
  email: string
  youtubeChannelName: string
  youtubeChannelUrl: string
  youtubeCustomUrl: string
  youtubeStudioUrl: string
}
export interface FastCutProfile {
  id: number
  email: string
  name: string
  termsAccepted: boolean
}

export async function getProfile() {
  try {
    const data: FastCutProfile = await API.get('/fast-cut-user/profile', {})
    return data
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getMe(): Promise<Me> {
  try {
    return await API.get('/fast-cut-user/me', {})
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getMePlan(): Promise<UserPlan> {
  try {
    return await API.get('/fast-cut-user/me/plan', {})
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function updateYoutubeAuth(data: any) {
  try {
    const { youtube } = data
    return await API.put('fast-cut-user/youtube', youtube[0])
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function logOut(data: any) {
  try {
    return await API.post(
      '/fast-cut-user/logout',
      {},
      { withCredentials: true }
    )
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function deleteYoutubeAccount(data: any) {
  try {
    return await API.delete('/fast-cut-user/youtube/account/channel', {
      data,
    })
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function acceptedTerms(acceptedTerms: boolean) {
  try {
    const result = await API.post('/fast-cut-user/terms', { acceptedTerms })
    return result
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function registInviteCode(data: any) {
  try {
    return await API.post('/fast-cut-user/regist-invite-code', data)
  } catch (error) {
    // 오류 처리
    throw error
  }
}
