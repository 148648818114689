import { useQuery } from '@tanstack/react-query'
import { getAutoChargeList, getPointList } from '../../../service/point'

function useGetAutoChargeList() {
  return useQuery({
    queryKey: ['get-auto-charge-list'],
    queryFn: () => getAutoChargeList(),
    staleTime: 6 * 1000,
  })
}

export default useGetAutoChargeList
