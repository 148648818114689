import { useQuery } from '@tanstack/react-query'
import { getPlans } from '../../../service/plans'

function useGetPlans() {
  return useQuery({
    queryKey: ['get-plans'],
    queryFn: () => getPlans(),
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 600000,
  })
}

export default useGetPlans
