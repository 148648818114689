import React from 'react'

export const GuideToUse = () => {
  return (
    <div className=" w-[360px] md:w-full h-auto p-[40px]  bg-[#E7F9F7] flex flex-col items-center gap-[10px] border rounded-[20px] mt-[40px]">
      <div className="flex flex-col items-center gap-[4px]">
        <p className="text-[#2E343D] font-bold leading-[28.8px] text-center text-[18px] md:text-[24px]">
          💁🏻 패스트컷 사용 가이드
        </p>
        <p className="text-main text-[10px] md:text-[14px] font-bold">
          패스트컷을 잘 사용하시려면 가이드를 한 번씩 읽어봐주세요!
        </p>
      </div>
      <div className="w-[343px] md:w-[530px] flex justify-between"> 
        <div className="flex flex-col gap-[16px]">
          <p className="flex gap-2">
            <img
              src="/assets/img/coupang_icon.png"
              alt="youtube icon"
              className="md:w-[24px] md:h-[24px] w-[16px] h-[16px]"
            />
            <a
              href="https://solution.fastseller.shop/15cc8c5d-bcd4-4d50-b145-4f32bad40634"
              target="_blank"
              rel="noreferrer"
              className="text-[10px] md:text-base"
            >
              쿠팡 파트너스 가이드
            </a>
          </p>
          <p className="flex gap-2">
            <img
              src="/assets/img/ali_icon.png"
              alt="youtube icon"
              className="md:w-[24px] md:h-[24px] w-[16px] h-[16px]"
            />
            <a
              href="https://solution.fastseller.shop/2ad66cae-7290-42eb-a3f4-83fcf3fc15c8"
              target="_blank"
              rel="noreferrer"
              className="text-[10px] md:text-base"
            >
              알리익스프레스 어필리에이트 가이드
            </a>
          </p>
          <p className="flex gap-2">
            <img
              src="/assets/img/icon_shorts.png"
              alt="youtube icon"
              className="md:w-[24px] md:h-[24px] w-[16px] h-[16px]"
            />
            <a
              href="https://angelleague.notion.site/6abfba9240964bd88fd4b7981850de3a?pvs=4"
              target="_blank"
              rel="noreferrer"
              className="text-[10px] md:text-base"
            >
              숏폼 업로드 가이드
            </a>
          </p>
          <p className="flex gap-2">
            <img
              src="/assets/img/icon_logo.png"
              alt="youtube icon"
              className="md:w-[24px] md:h-[24px] w-[16px] h-[16px]"
            />
            <a
              href="https://www.notion.so/angelleague/cecbe421d6fa4629baf9d61173c8842c?pvs=4"
              target="_blank"
              rel="noreferrer"
              className="text-[10px] md:text-base"
            >
              패스트컷 사용가이드
            </a>
          </p>
          <p className="flex gap-2">
            <img
              src="/assets/img/icon_logo.png"
              alt="youtube icon"
              className="md:w-[24px] md:h-[24px] w-[16px] h-[16px]"
            />
            <a
              href="https://angelleague.notion.site/0d23bb71864942dca2bbf89c60e4a15b?pvs=4"
              target="_blank"
              rel="noreferrer"
              className="text-[10px] md:text-base"
            >
              라이트플랜 영상 업로드 방법 가이드
            </a>
          </p>
          <p className="flex gap-2">
            <img
              src="/assets/img/icon_logo.png"
              alt="youtube icon"
              className="md:w-[24px] md:h-[24px] w-[16px] h-[16px]"
            />
            <a
              href="https://angelleague.notion.site/36249f9edf584c56a6c13d69fc9a05ad"
              target="_blank"
              rel="noreferrer"
              className="text-[10px] md:text-base"
            >
              프리미엄 플랜 영상 업로드 방법 가이드
            </a>
          </p>
        </div>
        <div className="flex flex-col gap-[16px]">
          <p className="flex gap-2">
            <img
              src="/assets/img/icon_youtube.png"
              alt="youtube icon"
              className="md:w-[24px] md:h-[24px] w-[16px] h-[16px]"
            />
            <a
              href="https://solution.fastseller.shop/42517f3c-b0f0-416b-b57f-4bf8dd57fbfc"
              target="_blank"
              rel="noreferrer"
              className="text-[10px] md:text-base"
            >
              유튜브 채널 권한 설정 가이드
            </a>
          </p>
          <p className="flex gap-2">
            <img
              src="/assets/img/icon_youtube.png"
              alt="youtube icon"
              className="md:w-[24px] md:h-[24px] w-[16px] h-[16px]"
            />
            <a
              href="https://angelleague.notion.site/10b539b31e9244e5b13637383f27d2e8"
              target="_blank"
              rel="noreferrer"
              className="text-[10px] md:text-base"
            >
              유튜브 고급기능 신청 가이드
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
