import { useQuery } from '@tanstack/react-query'
import { getAIPromptDuration, getBgm } from '../../service/list'

function useGetPromptDuration() {
  return useQuery({
    queryKey: ['get-prompt-duration'],
    queryFn: () => getAIPromptDuration(),
    staleTime: 6 * 1000,
  })
}

export default useGetPromptDuration
