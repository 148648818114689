import { UseQueryResult, useMutation, useQuery } from '@tanstack/react-query'
import {
  getAffiliateProgram,
  getAnnagio,
  getAnnagioSource,
} from '../../../service/event'

function useGetAffiliateProgramByCode() {
  return useMutation({
    mutationKey: ['get-affiliate-program'],
    mutationFn: getAffiliateProgram,
  })
}

export default useGetAffiliateProgramByCode
