import { useQuery } from '@tanstack/react-query'
import { getMe, getMePlan } from '../../service/user'

function useGetMePlan() {
  return useQuery({
    queryKey: ['get-me-plan'],
    queryFn: () => getMePlan(),
    refetchOnWindowFocus: true,
    retry: false,
    staleTime: 1000,
  })
}

export default useGetMePlan
