import React, { Dispatch, PropsWithChildren, SetStateAction } from 'react'
interface Props {
  bgmTooltip: boolean
  setBgmTooltip: Dispatch<SetStateAction<boolean>>
}

const BgmTooltip = ({
  children,
  bgmTooltip,
  setBgmTooltip,
}: PropsWithChildren<Props>) => {
  return (
    <>
      {' '}
      <img
        src="assets/img/icon_tooltip.png"
        alt="툴팁"
        className=" w-[20px] h-[20px]"
        onMouseEnter={() => {
          setBgmTooltip(true)
        }}
      />
      {bgmTooltip && (
        <div className="flex flex-col p-[16px] md:p-[32px] gap-[10px] bg-white w-[200px] md:w-[366px] h-auto absolute border-[3px] border-main  rounded-[20px] bottom-[20px] left-20">
          <p className="font-bold md:text-[24px] text-base flex justify-between items-center">
            배경음악이란?
            <button
              onClick={() => {
                setBgmTooltip(false)
              }}
            >
              <img
                src="assets/img/Icon_x.png"
                alt="icon_x"
                className="md:w-[40px] md:h-[40px] w-[25px] h-[25px]"
              />
            </button>
          </p>
          <p className=" md:text-base text-[10px]">
            숏폼 영상 전반에 은은하게 깔려있는 음악을 말해요. 배경음악이 있으면
            목소리만 있는 것 보다 영상이 완성도있게 느껴지니 나의 숏폼에
            어울리는 배경음악을 선택해보세요!
          </p>
          <p className="flex gap-2 items-center">
            <img src="assets/img/cash.png" alt="point" width={11} height={13} />
            <span className="text-main">30캐시</span>
          </p>
        </div>
      )}
    </>
  )
}

export default BgmTooltip
