import classNames from 'classnames'
import React, { InputHTMLAttributes } from 'react'
import { InputType } from 'zlib'

export const Input = (props: any) => {
  const { error, inputRef, isCheck, userCheck } = props
  const inputClass = classNames(
    'w-[343px] text-base md:w-[736px] h-[70px] border-[1px] rounded-[5px] p-[16px]',
    {
      'border-error focus:outline-none': error,
      'border-[#D9DEE8]': !error,
      'opacity-[0.5]': isCheck,
    }
  )
  return (
    <div>
      <input
        {...props}
        className={inputClass}
        ref={inputRef}
        onClick={userCheck}
      />
    </div>
  )
}

export const InputThumbnail = (props: any) => {
  const { error, inputRef, isCheck, userCheck, width, height } = props

  const inputClass = classNames(
    ` ${width ?? 'w-[342px]'}  ${
      height ? `w-[${height}px]` : ' h-[49px]'
    }  text-base   border-[1px] rounded-[10px] p-[16px]`,
    {
      'border-error focus:outline-none': error,
      'border-[#D9DEE8]': !error,
      'opacity-[0.5]': isCheck,
    }
  )

  return (
    <div>
      <input
        {...props}
        className={inputClass}
        ref={inputRef}
        onClick={userCheck}
      />
    </div>
  )
}
