import { useQuery } from '@tanstack/react-query'
import { getTransition } from '../../service/list'

function useGetTransition() {
  return useQuery({
    queryKey: ['get-transition'],
    queryFn: () => getTransition(),
    staleTime: 6 * 1000,
  })
}

export default useGetTransition
