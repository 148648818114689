import { useQuery } from '@tanstack/react-query'
import { getFreePlan, getPlans } from '../../../service/plans'

function useGetFreePlan() {
  return useQuery({
    queryKey: ['get-free-plan'],
    queryFn: () => getFreePlan(),
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 600000,
  })
}

export default useGetFreePlan
