import axios from 'axios'
import { removeLocalStorage } from '../utils/common'

// baseURL 값은 URL 앞에 추가됨
const baseURL = `${process.env.REACT_APP_API_URL}`

const API = axios.create({
  baseURL,
  timeout: Number(process.env.REACT_APP_REQUEST_TIMEOUT),
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
})

API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  }
  // eslint-disable-next-line no-param-reassign
  // config.headers.Authorization = `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI4cFEzMG9IUnZoY29YRUVDUGppa2RnPT0iLCJpc3MiOiJDb2RlSmF2YSIsImlhdCI6MTY4OTMyMDQxMSwiZXhwIjoxNjg5MzIwNDcxfQ.x0N2c2YOYNHlafzn8oaOUrSjrAaExaqJ90cvO9CDrfhnKSUrUAkeXV642Hb5r-iQj91TvFL4crrD8h_ohqvNRQ`
)

// API 호출 후 반환된 결과 처리 및 변환
API.interceptors.response.use(
  (response) => {
    const error = response.data?.error
    if (error) {
      const { code } = error
      if (code === '401') {
        removeLocalStorage('token')
        window.location.href = '/login'
      }
      const newError = { ...error }
      throw newError
    }

    return response.data
  },
  (error) => Promise.reject(error)
)

export default API
