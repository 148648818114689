import { useQuery } from '@tanstack/react-query'
import { getSubTitle } from '../../service/list'

function useGetSubtitle() {
  return useQuery({
    queryKey: ['get-subtitle'],
    queryFn: () => getSubTitle(),
    staleTime: 6 * 1000,
  })
}

export default useGetSubtitle
