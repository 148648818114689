import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { createVideo } from '../../service/list'
import { discountCodeCheck } from '../../service/payment'

export const useDiscount = () => {
  return useMutation({
    mutationKey: ['discount'],
    mutationFn: discountCodeCheck,
  })
}
