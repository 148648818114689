import API from './api'

export async function saveUtm({ utmSource, utmCampaign, trackType }: any) {
  try {
    const data = await API.post(
      `/utm-track/${utmSource}/${utmCampaign}/${trackType}`,
      {}
    )
    return data
  } catch (error) {
    // 오류 처리
    throw error
  }
}
