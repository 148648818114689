import axios from 'axios'
import API from './api'

export interface AnnagioType {
  category: string
  name: string
  path: string
  type: string
}

export interface EventBlogType {
  id?: number
  type: string
  service: string
  status?: string
  link: string
  etc: string
}
export async function getAnnagio(bool: boolean) {
  try {
    const data: AnnagioType[] = await API.get(
      `/affiliate/annagio?terms=${bool}`
    )

    return data
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getAnnagioSource(bool: boolean): Promise<string> {
  try {
    const data: string = await API.get(
      `/affiliate/annagio/source?terms=${bool}`
    )
    return data
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getAffiliateProgram(affiliateCode: string): Promise<any> {
  try {
    const data = await API.get(`/affiliate/${affiliateCode}`)
    return data
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function checkBlogExists(url: string): Promise<any> {
  try {
    const data = await API.get(`/event/blog?url=${url}`)
    return data
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function checkYoutubeExists(url: string): Promise<any> {
  try {
    const data = await API.get(`/event/youtube?url=${url}`)
    return data
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function checkInstagramExists(url: string): Promise<any> {
  try {
    const data = await API.get(`/event/instagram?url=${url}`)
    return data
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function checkTiktokExists(url: string): Promise<any> {
  try {
    const data = await API.get(`/event/tiktok?url=${url}`)
    return data
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function saveBlog(data: EventBlogType[]) {
  try {
    return await API.post(`/event/blog-event`, {
      blogEventLinks: data,
    })
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function authChannel(data: EventBlogType[]) {
  try {
    return await API.post(`/event/blog-event/auth`, {
      channels: data,
    })
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getBlogEvent(): Promise<EventBlogType[]> {
  try {
    const data: EventBlogType[] = await API.get(`/event/blogs`)
    return data
  } catch (error) {
    // 오류 처리
    throw error
  }
}
