import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { getProducts } from '../../service/list'

export const useGetProducts = () => {
  return useQuery({
    queryKey: ['get-products'],
    queryFn: () => getProducts(),
    staleTime: 1000,
  })
}
