import { useQuery } from '@tanstack/react-query'
import { getMe, getMePlan } from '../../service/user'
import { getUsageRequest } from '../../service/list'

function useGetUsageRequest(page: number, pageSize: number) {
  return useQuery({
    queryKey: ['get-usage-request'],
    queryFn: () => getUsageRequest(page, pageSize),
    refetchOnWindowFocus: true,
    retry: false,
    staleTime: 1000,
  })
}

export default useGetUsageRequest
