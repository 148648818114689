import { useQuery } from '@tanstack/react-query'
import { getBgm, getPopupContent } from '../../service/list'

function useGetPopupContent() {
  return useQuery({
    queryKey: ['get-popup-content'],
    queryFn: () => getPopupContent(),
  })
}

export default useGetPopupContent
