import { useQuery } from '@tanstack/react-query'
import { getPlans, getUsageMonthly } from '../../../service/plans'

function useGetUsageMonthly() {
  return useQuery({
    queryKey: ['get-usage-monthly'],
    queryFn: () => getUsageMonthly(),
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 600000,
  })
}

export default useGetUsageMonthly
