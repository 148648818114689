import { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { ShadowBox } from '../ShadowBox'
import { isMobile } from 'react-device-detect'

export const Layout = ({ children }: PropsWithChildren) => {
  const [isKakao, setKakao] = useState<boolean>(false)
  const popupRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
      setKakao(false)
    }
  }
  useEffect(() => {
    if (isKakao) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isKakao])
  return (
    <div className="">
      {children}

      {isMobile ? (
        isKakao ? (
          <div ref={popupRef} className="fixed bottom-10 right-[20px]  z-[100]">
            <a
              href="https://open.kakao.com/o/gA2SLC8e"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="assets/img/kakaochat.png"
                alt="kakaotalk"
                className="w-[320px] y-[95px]"
              />
            </a>

            <a
              href="http://pf.kakao.com/_CFxfHxj/chat"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="assets/img/kakaoplus.png"
                alt="kakaotalk"
                className="w-[350px] y-[95px]"
              />
            </a>
          </div>
        ) : (
          <>
            {' '}
            <div
              className="fixed bottom-10 right-[20px]  z-[100] "
              onClick={() => {
                setKakao(true)
              }}
            >
              <img
                src="assets/img/mobile_kakao.png"
                alt="kakaotalk"
                className="w-[80px] y-[80px] "
                // style={{ boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}
              />
            </div>
          </>
        )
      ) : (
        <div className="fixed bottom-10 right-[20px]  z-[100]">
          <a
            href="https://open.kakao.com/o/gA2SLC8e"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="assets/img/kakaochat.png"
              alt="kakaotalk"
              className="w-[320px] y-[95px]"
            />
          </a>

          <a
            href="http://pf.kakao.com/_CFxfHxj/chat"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="assets/img/kakaoplus.png"
              alt="kakaotalk"
              className="w-[350px] y-[95px]"
            />
          </a>
        </div>
      )}
    </div>
  )
}
