import { useQuery } from '@tanstack/react-query'
import { getAIPromptType, getMakeTotal, getVoice } from '../../service/list'

function useGetMakeTotal() {
  return useQuery({
    queryKey: ['get-make-total'],
    queryFn: () => getMakeTotal(),
    staleTime: 6 * 1000,
  })
}

export default useGetMakeTotal
