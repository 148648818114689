import React, { Dispatch, PropsWithChildren, SetStateAction } from 'react'
interface Props {
  animationTooltip: boolean
  setAnimationTooltip: Dispatch<SetStateAction<boolean>>
}

const AnimationTitleTooltip = ({
  children,
  animationTooltip,
  setAnimationTooltip,
}: PropsWithChildren<Props>) => {
  return (
    <>
      {' '}
      <img
        src="assets/img/icon_tooltip.png"
        alt="툴팁"
        className=" w-[20px] h-[20px]"
        onMouseEnter={() => {
          setAnimationTooltip(true)
        }}
      />
      {animationTooltip && (
        <div className="flex flex-col p-[16px] md:p-[32px] gap-[10px] bg-white w-[300px] md:w-[366px] h-auto absolute border-[3px] border-main  rounded-[20px] -bottom-52 left-10  md:left-52  z-50">
          <p className="font-bold md:text-[24px] text-base flex justify-between md:items-center">
            이미지 애니메이션 효과란?{' '}
            <button
              onClick={() => {
                setAnimationTooltip(false)
              }}
              className="flex flex-col h-full"
            >
              <img
                src="assets/img/Icon_x.png"
                alt="icon_x"
                className="md:w-[40px] md:h-[40px] w-[25px] h-[25px]"
              />
            </button>
          </p>
          <p className=" md:leading-[19.2px] md:text-base  leading-[19.2px] text-[10px]">
            [효과 있음]을 선택하시면 링크를 입력하신 페이지에 있는{' '}
            <span className="text-main font-bold">
              이미지 비율에 따라 옆으로 흐르거나 줌 인(zoom in) 효과
            </span>
            가 적용됩니다. 애니메이션 효과는 추후 다양하게 추가될 예정이니
            기대해주세요!
          </p>
          <div className="flex justify-center">
            <img
              src="assets/img/animation_example2.png"
              alt="example"
              width={240}
              height={424}
              className=" "
            />
          </div>
        </div>
      )}
    </>
  )
}

export default AnimationTitleTooltip
