import classNames from 'classnames'

export const TextArea = (props: any) => {
  const { error, inputRef, isCheck, userCheck } = props

  const inputClass = classNames(
    'w-[343px] text-base md:w-full  h-[123px]  rounded-[10px] p-[16px] bg-[#F6F8FB]',
    {
      'border border-error focus:outline-none': error,
      'border-[#D9DEE8]': !error,
      'opacity-[0.5]': isCheck,
    }
  )
  return (
    <textarea
      {...props}
      className={inputClass}
      ref={inputRef}
      onClick={userCheck}
    />
  )
}

export const TextAreaMyVideo = (props: any) => {
  const { error, inputRef, isCheck, userCheck, className } = props

  const inputClass = classNames(
    `w-[290px]  text-base md:w-full  h-[123px]  rounded-[10px] p-[16px] bg-[#F6F8FB] ${className}`,
    {
      'border border-error focus:outline-none': error,
      'border-[#D9DEE8]': !error,
      'opacity-[0.5]': isCheck,
    }
  )
  return (
    <textarea
      {...props}
      className={inputClass}
      ref={inputRef}
      onClick={userCheck}
    />
  )
}
