import { UseQueryResult, useMutation, useQuery } from '@tanstack/react-query'
import { getList, postList } from '../../service/list'
import { authGoogle } from '../../service/auth'
import { getProfile } from '../../service/user'

function usePostList() {
  return useMutation({
    mutationKey: ['post-list'],
    mutationFn: postList,
  })
}

export default usePostList
