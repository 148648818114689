import { useMutation } from '@tanstack/react-query'
import {
  getPointOrderId,
  updateRequestPurchasePoint,
} from '../../../service/point'

export const useUpdateRequestPurchasePoint = () => {
  return useMutation({
    mutationKey: ['update-point-entity'],
    mutationFn: updateRequestPurchasePoint,
  })
}
