import React, { PropsWithChildren } from 'react'
import { useNavigate } from 'react-router-dom'
import { handleClickPromotionBanner } from '../../utils/affiliate'
interface PromotionBannerProps {
  daysLeft: number
  hoursLeft: number
  affiliateImage: string
  affiliateDescription: string
  className?: string
}

export const PromotionBanner = ({
  daysLeft,
  hoursLeft,
  affiliateImage,
  affiliateDescription,
  className,
}: PropsWithChildren<PromotionBannerProps>) => {
  const navigate = useNavigate()
  return (
    <div
      className={`flex flex-col items-center  cursor-pointer ${className}`}
      onClick={() => {
        handleClickPromotionBanner(navigate)
      }}
    >
      <p className="font-bold text-[24px] flex items-center gap-2 md:flex-row flex-col">
        <span className="flex items-center">
          {' '}
          <img
            src={affiliateImage}
            alt="logo"
            className="w-[33px] h-[33px] mr-1"
          />
          {affiliateDescription}
        </span>
        <span className="text-[#464DEE]">
          {hoursLeft > 24
            ? `제휴 혜택 ${daysLeft}일 남았어요`
            : `제휴 혜택 ${hoursLeft}시간 남았어요`}
        </span>
      </p>
    </div>
  )
}
