import { useMutation } from '@tanstack/react-query'
import {
  getPointOrderId,
  selectLuckyDraw,
  updateRequestPurchasePoint,
} from '../../../service/point'

export const useLuckyDraw = () => {
  return useMutation({
    mutationKey: ['select-lucky-draw'],
    mutationFn: selectLuckyDraw,
  })
}
