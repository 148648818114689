import classNames from 'classnames'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const ErrorBox = () => {
  const navigate = useNavigate()
  return (
    <div className="bg-[#FFD8D8]  text-error md:w-full w-[335px] md:h-[70px] h-[51px] rounded-[40px] flex md:flex-row flex-col items-center px-[40px] py-[32px]  text-base justify-center ">
      <p className="font-bold w-full">
        <button
          className="w-full  flex justify-center md:justify-start"
          onClick={() => {
            navigate('/youtube-auth')
          }}
        >
          {`필수! 자동업로드 설정 하러가기 >>`}
        </button>
      </p>
    </div>
  )
}

export const YoutubeAuthCircle = (props: any) => {
  const { className } = props
  const navigate = useNavigate()
  return (
    <div
      className={classNames(className)}
      onClick={() => {
        navigate('/youtube-auth')
      }}
    >
      <button className="w-full  flex justify-center ">
        {`🧑‍💻 필수! 자동업로드 설정 하러가기 >`}
      </button>
    </div>
  )
}
