import React from 'react'
import { BeatLoader } from 'react-spinners'

export const Spinner = () => {
  return (
    <div className="h-screen w-full fixed left-0 top-0 flex flex-col justify-center items-center bg-black bg-opacity-30 text-center z-50">
      <BeatLoader color="#00B7B9"></BeatLoader>
    </div>
  )
}
