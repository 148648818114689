import React, { PropsWithChildren } from 'react'
interface ModalProps {
  isModal: boolean
}

export const Modal = ({ children, isModal }: PropsWithChildren<ModalProps>) => {
  if (!isModal) return null
  return (
    <div className="h-screen w-full fixed left-0 top-0 flex flex-col justify-center items-center bg-black bg-opacity-50 text-center  z-50">
      {children}
    </div>
  )
}
