import { useNavigate } from 'react-router-dom'
import { PlanPromotionBanner } from '../components/affiliate/PlanPromotionBanner'

/**
 * 프로모션 배너를 클릭시 가격안내 페이지로 이동
 *
 * @param {func} navigate - useNavegate 를 넘겨줌
 *
 */
export const handleClickPromotionBanner = (navigate: any) => {
  navigate('/price-guide')
  return
}
/**
 * 특정 제휴 코드에 따른 프로모션을 처리하는 함수입니다.
 *
 * @param {string} affiliateCode - 제휴 코드. 이 코드를 통해 어떤 프로모션 화면을 보여줄지 결정됩니다.
 *    - 제휴사 식별자 역학을 합니다 ex) 'ZZAL'
 *
 * @return {JSX.Element | undefined} - 해당 제휴 코드에 따라 표시될  플랜 프로모션 모달을 반환합니다.
 *    - 반환된 값은 프로모션을 보여주는 컴포넌트입니다.
 *    - 제휴사별로 보여주는 컴포넌트는 다릅니다.
 *    - 정의되지 않은 제휴 코드일 경우 아무 값도 반환하지 않습니다.
 */
export const planPromotionByAffiliate = (affiliateCode: string) => {
  // affiliateCode에 따라서 보여줘야하는 프로모션 모습이 달라진다.
  switch (affiliateCode) {
    case 'ZZAL':
      return <></>
    default:
      return <></>
  }
}

/**
 * 특정 제휴 코드에 따른 프로모션을 처리하는 함수입니다.
 *
 * @param {string} affiliateCode - 제휴 코드. 이 코드를 통해 어떤 프로모션 화면을 보여줄지 결정됩니다.
 *    - 제휴사 식별자 역학을 합니다 ex) 'ZZAL'
 *
 * @return {JSX.Element | undefined} - 해당 제휴 코드에 따라 표시될 캐시 프로모션 모달을 반환합니다.
 *    - 반환된 값은 프로모션을 보여주는 컴포넌트입니다.
 *    - 제휴사별로 보여주는 컴포넌트는 다릅니다.
 *    - 정의되지 않은 제휴 코드일 경우 아무 값도 반환하지 않습니다.
 */
export const cashPromotionByAffiliate = (affiliateCode: string) => {
  // affiliateCode에 따라서 보여줘야하는 프로모션 모습이 달라진다.
  switch (affiliateCode) {
    case 'ZZAL':
      return <></>
    default:
      return <></>
  }
}
