import { UseQueryResult, useMutation, useQuery } from '@tanstack/react-query'
import { getList } from '../../service/list'
import { authGoogle } from '../../service/auth'
import { getProfile } from '../../service/user'
import { getPaymentOrderRequests } from '../../service/payment'

function useGetPaymentOrderRequests() {
  return useMutation({
    mutationKey: ['get-payment-order-requests'],
    mutationFn: getPaymentOrderRequests,
  })
}

export default useGetPaymentOrderRequests
