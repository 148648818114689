import { UseQueryResult, useMutation, useQuery } from '@tanstack/react-query'

import { createChallenge, updateChallenge } from '../../../service/challenge'

function useUpdateChallenge() {
  return useMutation({
    mutationKey: ['update-challenge'],
    mutationFn: updateChallenge,
  })
}

export default useUpdateChallenge
