import { UseQueryResult, useMutation, useQuery } from '@tanstack/react-query'

import { createChallenge } from '../../../service/challenge'

function useCreateChallenge() {
  return useMutation({
    mutationKey: ['create-challenge'],
    mutationFn: createChallenge,
  })
}

export default useCreateChallenge
