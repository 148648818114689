import { UseQueryResult, useMutation, useQuery } from '@tanstack/react-query'
import { createShortVideo, postList } from '../../service/list'

function useCreateShortVideo() {
  return useMutation({
    mutationKey: ['create-short-video'],
    mutationFn: createShortVideo,
  })
}

export default useCreateShortVideo
