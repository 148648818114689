import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
} from 'react'
import useGetFreePlan from '../hooks/query/plans/useGetFreePlan'
import { isMobile } from 'react-device-detect'

interface FreePlanModalProps {
  isFreePlanModal: boolean
  setFreePlanModal: Dispatch<SetStateAction<boolean>>
}

export const FreePlanModal = ({
  children,
  isFreePlanModal,
  setFreePlanModal,
}: PropsWithChildren<FreePlanModalProps>) => {
  const { data: freePlanInfo, refetch, isLoading } = useGetFreePlan()

  return (
    <div className="h-screen w-full fixed left-0 top-0 flex flex-col justify-center items-center bg-black bg-opacity-50 text-center  z-50 ">
      <div className="md:w-[816px] flex flex-col gap-[32px] mt-[32px] p-[32px] bg-[#E9EDF9] rounded-[20px] border-[2px] border-href overflow-y-auto md:h-auto h-[400px]">
        <div className="flex flex-col md:flex-row w-full justify-end">
          <p className="flex w-[40px] h-[40px] justify-end">
            <button
              type="button"
              onClick={() => {
                setFreePlanModal(false)
              }}
            >
              <img
                src="assets/img/Icon_x.png"
                alt="icon_x"
                height={40}
                width={40}
              />
            </button>
          </p>
        </div>
        <div className="flex flex-col justify-center items-center gap-[12px]">
          <p className="font-bold text-[24px] text-href">
            {freePlanInfo?.name}하기
          </p>
          <p>
            <span className="font-bold text-[32px]">0원</span>
          </p>
        </div>
        {isMobile ? (
          <>
            <div className="flex flex-col">
              <div className="w-[100%] flex flex-col gap-[24px]">
                {freePlanInfo?.planDetail.map((el, index) => {
                  return (
                    <>
                      {el.type === 'feature' && (
                        <p className="font-bold text-[18px] flex items-center gap-[5px]">
                          <img
                            src="assets/img/check_circle_blue.png"
                            alt="check_circle"
                            width={24}
                            height={24}
                          />
                          {el.name}
                        </p>
                      )}
                    </>
                  )
                })}
                {freePlanInfo?.planDetail.map((el, index) => {
                  return (
                    <>
                      {el.type === 'function' && (
                        <p className="font-bold text-[18px] flex items-center gap-[5px]">
                          <img
                            src="assets/img/check_circle_blue.png"
                            alt="check_circle"
                            width={24}
                            height={24}
                          />
                          {el.name}
                        </p>
                      )}
                    </>
                  )
                })}
              </div>
            </div>{' '}
          </>
        ) : (
          <div className="flex">
            <div className="w-[50%] flex flex-col gap-[24px]">
              {freePlanInfo?.planDetail.map((el, index) => {
                return (
                  <>
                    {el.type === 'feature' && (
                      <p className="font-bold text-[18px] flex items-center gap-[5px]">
                        <img
                          src="assets/img/check_circle_blue.png"
                          alt="check_circle"
                          width={24}
                          height={24}
                        />
                        {el.name}
                      </p>
                    )}
                  </>
                )
              })}
            </div>
            <div className="w-[50%] flex flex-col gap-[24px]">
              {freePlanInfo?.planDetail.map((el, index) => {
                return (
                  <>
                    {el.type === 'function' && (
                      <p className="font-bold text-[18px] flex items-center gap-[5px]">
                        <img
                          src="assets/img/check_circle_blue.png"
                          alt="check_circle"
                          width={24}
                          height={24}
                        />
                        {el.name}
                      </p>
                    )}
                  </>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
