import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  FreeCouponToolitip,
  MissionTooltip,
  SecretMissionTooltip,
} from '../components/shortvideo/CouponToolitip'

export const CouponPack = () => {
  const navigate = useNavigate()
  const [couponTooltipStatus, setCouponTooltipStatus] = useState(false)
  const [secretCouponTooltipStatus, setSecretCouponTooltipStatus] =
    useState(false)
  const moveToPriceGuide = () => {
    navigate('/price-guide')
  }
  const handleCopyClipBoard = async (copyText: string, successText: string) => {
    try {
      await navigator.clipboard.writeText(copyText)
      toast.success(successText, {
        position: 'bottom-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    } catch (e) {}
  }

  return isMobile ? (
    <>
      <div className="w-full flex flex-col items-center relative ">
        <div
          className="image1  bg-cover bg-center w-[375px] h-[215px]"
          style={{
            backgroundImage: "url('assets/img/challenge/1_mobile.png')",
          }}
        ></div>
        <div
          className="image1  bg-cover bg-center w-[375px] h-[607px] flex items-end justify-center"
          style={{
            backgroundImage: "url('assets/img/challenge/2.png')",
          }}
        >
          <button
            className="w-[335px] rounded-[40px] h-[60px] mb-[30px] text-[18px] font-bold"
            style={{
              background: 'linear-gradient(to bottom, #E2DBFF,#A895FF)',
            }}
            onClick={() => {
              navigate('/video-editor')
            }}
          >
            영상 생성 시작
          </button>
        </div>
        <div
          className="image1  bg-cover bg-center w-[375px] h-[583px] flex items-end justify-center relative"
          style={{
            backgroundImage: "url('assets/img/challenge/3.png')",
          }}
        >
          {' '}
          <button
            className="w-[335px] rounded-[40px] h-[60px] mb-5 text-[18px] font-bold "
            style={{
              background: 'linear-gradient(to bottom, #EDE093,#E5A907)',
            }}
            onClick={() => {
              setCouponTooltipStatus((prev) => !prev)
            }}
          >
            선착순 미션 참여
          </button>
          <MissionTooltip
            couponTooltipStatus={couponTooltipStatus}
            setCouponTooltipStatus={setCouponTooltipStatus}
          />
        </div>
        <div
          className="image1  bg-cover bg-center w-[375px] h-[529px] flex items-end justify-center "
          style={{
            backgroundImage: "url('assets/img/challenge/4.png')",
          }}
        >
          {' '}
          <button
            className="w-[335px] rounded-[40px] h-[60px] mb-5 text-[18px] font-bold "
            style={{
              background: 'linear-gradient(to bottom, #FFD5EC,#DC95C6)',
            }}
            onClick={() => {
              navigate('/my-videos')
            }}
          >
            50% 할인 쿠폰 받기
          </button>
        </div>
        <div
          className="image1  bg-cover bg-center w-[375px] h-[533px] "
          style={{
            backgroundImage: "url('assets/img/challenge/5.png')",
          }}
        ></div>
        <div
          className="image1  bg-cover bg-center w-[375px] h-[403px] "
          style={{
            backgroundImage: "url('assets/img/challenge/6.png')",
          }}
        ></div>
        <div className="  w-full flex flex-col bg-[#181830] px-5 py-8">
          <p className="font-[800] font-[Tenada] text-[24px] text-white">
            기타 안내 사항
          </p>
          <div>
            <ul className=" list-disc text-[#B6BBC6] ml-4">
              <li>
                선착순 1,000명 참여 이후 별도 공지 없이 이벤트 추가 참여가
                중단됩니다.{' '}
              </li>
              <li>
                무료 체험시 50개 모두 정상 생성 요청 되고, 자동 업로드 설정이
                완료되어야 영상 생성 및 업로드가 시작됩니다.{' '}
              </li>
              <li>영상 생성 요청은 체험 기한 내 완료되어야 합니다.</li>
              <li>
                동일한 상품 링크나 제목을 반복적으로 사용하거나, 업로드된 영상을
                삭제하는 등의 부당 행위가 발견될 경우 체험 자격이 유지되지
                않습니다.
              </li>
              <li>기존에 등록된 유튜브 채널은 사용이 불가합니다.</li>
              <li>
                1인 1계정 참여 가능합니다. 동일인이 여러 계정을 생성하여
                반복적으로 참여한 정황이 발견될 경우 부당 행위로 판단하여 모든
                혜택을 제공하지 않습니다.
              </li>
              <li>
                영상 생성 체험 미션 성공으로 지급된 50% 할인쿠폰은 프로/프리미엄
                연간결제시 적용되며, 체험 기한+3일 동안 사용 가능합니다.
              </li>
              <li>
                경품 이벤트는 24년 12월 31일까지 체험 미션 성공자를 대상으로
                하며, 25년 1월 중에 추첨합니다.
              </li>
              <li>
                신규 회원 대상 이벤트로, 이미 체험 플랜을 사용한 계정은 재참여가
                불가능합니다.{' '}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  ) : (
    // PC
    <>
      <div className="md:min-w-[100vw] w-full flex justify-center  h-auto relative ">
        <div className="md:w-[816px] w-full flex flex-col items-center ">
          <div
            className="image1  bg-cover bg-center w-[816px] h-[215px]"
            style={{
              backgroundImage: "url('assets/img/challenge/1.png')",
            }}
          ></div>
          <div className="image3   w-[816px] h-[607px] bg-[#5236CF] flex justify-center">
            <div
              className="image1  bg-cover bg-center w-[375px] h-[607px] flex items-end"
              style={{
                backgroundImage: "url('assets/img/challenge/2.png')",
              }}
            >
              <button
                className="w-full rounded-[40px] h-[60px] mb-[30px] text-[18px] font-bold"
                style={{
                  background: 'linear-gradient(to bottom, #E2DBFF,#A895FF)',
                }}
                onClick={() => {
                  navigate('/video-editor')
                }}
              >
                영상 생성 시작
              </button>
            </div>
          </div>
          <div className="image3   w-[816px] h-[607px] bg-[#181830] flex justify-center relative">
            <div
              className="image1  bg-cover bg-center w-[375px] h-[607px] flex items-end"
              style={{
                backgroundImage: "url('assets/img/challenge/3.png')",
              }}
            >
              {' '}
              <button
                className="w-full rounded-[40px] h-[60px] mb-5 text-[18px] font-bold"
                style={{
                  background: 'linear-gradient(to bottom, #EDE093,#E5A907)',
                }}
                onClick={() => {
                  setCouponTooltipStatus((prev) => !prev)
                }}
              >
                선착순 미션 참여
              </button>
            </div>
            <MissionTooltip
              couponTooltipStatus={couponTooltipStatus}
              setCouponTooltipStatus={setCouponTooltipStatus}
            />
          </div>
          <div className="image3   w-[816px] h-[529px] bg-[#4F0934] flex justify-center">
            <div
              className="image1  bg-cover bg-center w-[375px] h-[529px] flex items-end"
              style={{
                backgroundImage: "url('assets/img/challenge/4.png')",
              }}
            >
              {' '}
              <button
                className="w-full rounded-[40px] h-[60px] mb-5 text-[18px] font-bold "
                style={{
                  background: 'linear-gradient(to bottom, #FFD5EC,#DC95C6)',
                }}
                onClick={() => {
                  navigate('/my-videos')
                }}
              >
                50% 할인 쿠폰 받기
              </button>
            </div>
          </div>
          <div className="image3   w-[816px] h-[533px] bg-[#181830] flex justify-center">
            <div
              className="image1  bg-cover bg-center w-[375px] h-[533px] "
              style={{
                backgroundImage: "url('assets/img/challenge/5.png')",
              }}
            ></div>
          </div>
          <div className="  w-[816px] h-[403px] bg-[#4360EC] flex justify-center">
            <div
              className="image1  bg-cover bg-center w-[375px] h-[403px] "
              style={{
                backgroundImage: "url('assets/img/challenge/6.png')",
              }}
            ></div>
          </div>
          <div className="  w-[816px] flex flex-col bg-[#181830] px-5 py-8">
            <p className="font-[800] font-[Tenada] text-[24px] text-white">
              기타 안내 사항
            </p>
            <div>
              <ul className=" list-disc text-[#B6BBC6] ml-4">
                <li>
                  선착순 1,000명 참여 이후 별도 공지 없이 이벤트 추가 참여가
                  중단됩니다.{' '}
                </li>
                <li>
                  무료 체험시 50개 모두 정상 생성 요청 되고, 자동 업로드 설정이
                  완료되어야 영상 생성 및 업로드가 시작됩니다.{' '}
                </li>
                <li>영상 생성 요청은 체험 기한 내 완료되어야 합니다.</li>
                <li>
                  동일한 상품 링크나 제목을 반복적으로 사용하거나, 업로드된
                  영상을 삭제하는 등의 부당 행위가 발견될 경우 체험 자격이
                  유지되지 않습니다.
                </li>
                <li>기존에 등록된 유튜브 채널은 사용이 불가합니다.</li>
                <li>
                  1인 1계정 참여 가능합니다. 동일인이 여러 계정을 생성하여
                  반복적으로 참여한 정황이 발견될 경우 부당 행위로 판단하여 모든
                  혜택을 제공하지 않습니다.
                </li>
                <li>
                  영상 생성 체험 미션 성공으로 지급된 50% 할인쿠폰은
                  프로/프리미엄 연간결제시 적용되며, 체험 기한+3일 동안 사용
                  가능합니다.
                </li>
                <li>
                  경품 이벤트는 24년 12월 31일까지 체험 미션 성공자를 대상으로
                  하며, 25년 1월 중에 추첨합니다.
                </li>
                <li>
                  신규 회원 대상 이벤트로, 이미 체험 플랜을 사용한 계정은
                  재참여가 불가능합니다.{' '}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
