import { useQuery } from '@tanstack/react-query'
import {
  createInitialMyPointData,
  getMyPoint,
  getMyPointTotal,
  getPointList,
} from '../../../service/point'

export function useGetMyPointTotal() {
  return useQuery({
    queryKey: ['get-my-point-total'],
    queryFn: () => getMyPointTotal(),
    staleTime: 6 * 1000,
  })
}
