import { create } from 'zustand'
import { FastCutProfile } from '../service/user'

// 공동 사용되는 전역 변수 저장

const usePrevStore = create((set) => ({
  prevUrl: null,
  set: (payload: any) => set(() => payload),
  destroy: () => set({}, true),
}))

export default usePrevStore
