import { useQuery } from '@tanstack/react-query'
import { getMe } from '../../service/user'

function useGetMe() {
  return useQuery({
    queryKey: ['get-me'],
    queryFn: () => getMe(),
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 600000,
  })
}

export default useGetMe
