import React from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts'
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'

interface ChartData {
  date: string
  value: number
}
export const EmptyBarChart = (props: any) => {
  const { barCount, barWidth, isXAxis, chartHeight } = props
  const max = 80
  const generateData = (count: number): ChartData[] => {
    return Array.from({ length: count }, (_, i) => ({
      date: `${i + 1}`,
      value: 80,
    }))
  }

  const data = generateData(barCount)

  const CustomTooltip: React.FC<TooltipProps<ValueType, NameType>> = ({
    active,
    payload,
    label,
  }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            padding: '10px',
            borderRadius: '5px',
          }}
        >
          <p>{`${label}개월 차`}</p>
          <p>{`${payload[0].value}개 요청`}</p>
        </div>
      )
    }

    return null
  }
  return (
    <ResponsiveContainer width={284} height={chartHeight ?? 90}>
      <BarChart data={data} margin={{}}>
        <XAxis
          dataKey="date"
          axisLine={false} // X축 라인 제거
          tickLine={false} // X축 눈금선 제거
          ticks={[
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            '10',
            '11',
            '12',
          ]}
          tick={{ fontSize: 12, fill: '#D4D9DF' }}
          hide={isXAxis}
        />

        {/* Y축 숨기기 */}
        <YAxis hide />
        <Bar dataKey="value" radius={[10, 10, 10, 10]} stackId="a">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} width={barWidth} fill={'#E9EDF7'} />
          ))}
        </Bar>

        {/* 그라데이션 정의 */}
      </BarChart>
    </ResponsiveContainer>
  )
}
