import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { logOut } from '../../service/user'

export const useLogOut = () => {
  return useMutation({
    mutationKey: ['user-logout'],
    mutationFn: logOut,
  })
}
