import { UseQueryResult, useMutation, useQuery } from '@tanstack/react-query'
import { confirmPoint, getPromotionInfo } from '../../../service/point'

function useGetPromotion() {
  return useMutation({
    mutationKey: ['get-promotion'],
    mutationFn: getPromotionInfo,
  })
}

export default useGetPromotion
