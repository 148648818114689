import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { createVideo } from '../../service/list'
import { saveUtm } from '../../service/utm'

export const useSaveUtm = () => {
  return useMutation({
    mutationKey: ['save-utm'],
    mutationFn: saveUtm,
  })
}
