export type TitleType = {
  titleName: 'title1' | 'title2' | 'title3'
  titleContent: string
  placeholder: string
  maxLength: number
  color: string
  border?: string
  weight?: string
  size?: string
  bg?: string
  font?: string
  shadow?: string
  align?: string
  letter?: string
  padding?: string
  gradient?: { background: string; text: string }
  stroke?: string
  height?: string
}

export type TitleListType = {
  title: TitleType
  position: string
}

export type ThumbnailOptionType = {
  name: string
  titleList: TitleListType[]
  opacity?: string
  bg_gradient?: string
  border?: { weight: string; color: string }
  outline_border?: { weight: string; color: string }
  blank_box?: boolean
  img_url?: string
}

export type ThumbnailOptionsType = {
  [key: string]: ThumbnailOptionType
}

export const thumbnailOptions: ThumbnailOptionsType = {
  thumbnail_01: {
    name: 'thumbnail_01',
    img_url: 'https://pub.angelleague.io/fast_cut/thumbnail/thumbnail01.png',
    titleList: [
      {
        title: {
          titleName: 'title1',
          titleContent: '영상썸네일스타일',
          placeholder: '첫 번째 줄에 들어갈 내용을 입력 (10자 이내)',
          maxLength: 10,
          color: '#FFFFFF',
          weight: '900',

          font: 'TmonMonsori',
          size: '36.74px',
          shadow: '0px 0px 18.37px #000000',
          height: '37px',
        },
        position: '20%',
      },
      {
        title: {
          titleName: 'title2',
          titleContent: '내용을입력하시면',
          placeholder: '두 번째 줄에 들어갈 내용을 입력 (10자 이내)',
          maxLength: 10,
          color: '#FFFFFF',
          weight: '900',
          size: '36.74px',
          font: 'TmonMonsori',
          shadow: '0px 0px 18.37px #000000',
          height: '37px',
        },
        position: '40%',
      },
      {
        title: {
          titleName: 'title3',
          titleContent: '미리보기할수있어요',
          placeholder: '세 번째 줄에 들어갈 내용을 입력 (11자 이내)',
          maxLength: 11,
          color: '#FFFFFF',
          weight: '900',
          size: '36.74px',
          font: 'TmonMonsori',
          shadow: '0px 0px 18.37px #000000',
          height: '37px',
        },
        position: '60%',
      },
    ],
    opacity: '0.5',
  },
  thumbnail_02: {
    name: 'thumbnail_02',
    img_url: 'https://pub.angelleague.io/fast_cut/thumbnail/thumbnail02.png',
    titleList: [
      {
        title: {
          titleName: 'title1',
          titleContent: '영상썸네일스타일',
          placeholder: '첫 번째 줄에 들어갈 내용을 입력 (10자 이내)',
          maxLength: 10,
          color: 'rgba(255, 254, 112, 1)',
          weight: '400',
          size: '40px',
          font: 'BMDOHYEON',
          align: 'start',
          letter: '-3.21px',
          border: 'text-border-black',
          // stroke: '4.6px black',
          // gradient: {
          //   background:
          //     'linear-gradient(to bottom, rgba(255, 254, 112, 1), rgba(255, 199, 0, 1))',
          //   text: 'text',
          // },
        },
        position: '24%',
      },
      {
        title: {
          titleName: 'title2',
          titleContent: '내용을입력하시면',
          placeholder: '두 번째 줄에 들어갈 내용을 입력 (10자 이내)',
          maxLength: 10,
          color: 'rgba(175, 255, 112, 1)',
          border: 'text-border-black',
          weight: '400',
          size: '40px',
          font: 'BMDOHYEON',
          align: 'start',
          letter: '-3.21px',
          // stroke: '4.6px black',
          // gradient: {
          //   background:
          //     'linear-gradient(to bottom,rgba(175, 255, 112, 1),rgba(0, 255, 26, 1))',
          //   text: 'text',
          // },
        },
        position: '48%',
      },
      {
        title: {
          titleName: 'title3',
          titleContent: '미리보기할수있어요',
          placeholder: '세 번째 줄에 들어갈 내용을 입력 (11자 이내)',
          maxLength: 11,
          color: '#FFFFFF',
          border: 'text-border-black',
          weight: '400',
          size: '40px',
          font: 'BMDOHYEON',
          align: 'start',
          letter: '-3.21px',
          // stroke: '4.6px black',
          // gradient: {
          //   background:
          //     'linear-gradient(to bottom,rgba(255, 255, 255, 1),rgba(187, 187, 187, 1))',
          //   text: 'text',
          // },
        },
        position: '72%',
      },
    ],
    bg_gradient:
      'linear-gradient(to bottom,rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.5))',
  },
  thumbnail_03: {
    name: 'thumbnail_03',
    img_url: 'https://pub.angelleague.io/fast_cut/thumbnail/thumbnail03.png',
    titleList: [
      {
        title: {
          titleName: 'title1',
          titleContent: '영상썸네일스타일',
          placeholder: '첫 번째 줄에 들어갈 내용을 입력 (10자 이내)',
          maxLength: 10,
          color: '#A0FF90',
          border: 'text-border-black',
          weight: '400',
          size: '40px',
          font: 'Black Han Sans',
        },
        position: '35%',
      },
      {
        title: {
          titleName: 'title2',
          titleContent: '내용을입력하시면',
          placeholder: '두 번째 줄에 들어갈 내용을 입력 (10자 이내)',
          maxLength: 10,
          color: '#A0FF90',
          border: 'text-border-black',
          weight: '400',
          size: '40px',
          font: 'Black Han Sans',
        },
        position: '55%',
      },
      {
        title: {
          titleName: 'title3',
          titleContent: '미리보기할수있어요',
          placeholder: '세 번째 줄에 들어갈 내용을 입력 (11자 이내)',
          maxLength: 11,
          color: '#A0FF90',
          border: 'text-border-black',
          weight: '400',
          size: '40px',
          font: 'Black Han Sans',
        },
        position: '75%',
      },
    ],
    opacity: '0.2',
    border: { weight: '5px', color: '#A0FF90' },
  },
  thumbnail_04: {
    name: 'thumbnail_04',
    img_url: 'https://pub.angelleague.io/fast_cut/thumbnail/thumbnail04.png',
    titleList: [
      {
        title: {
          titleName: 'title1',
          titleContent: '영상썸네일스타일',
          placeholder: '첫 번째 줄에 들어갈 내용을 입력 (10자 이내)',
          maxLength: 10,
          color: '#FFFFFF',
          border: 'text-border-black',
          weight: '800',
          size: '30px',
          font: 'Tenada',
        },
        position: '20%',
      },
      {
        title: {
          titleName: 'title2',
          titleContent: '내용을입력하시면',
          placeholder: '두 번째 줄에 들어갈 내용을 입력 (10자 이내)',
          maxLength: 10,
          color: '#FFA490',
          border: 'text-border-black',
          weight: '800',
          size: '30px',
          font: 'Tenada',
        },
        position: '40%',
      },
      {
        title: {
          titleName: 'title3',
          titleContent: '미리보기할수있어요',
          placeholder: '세 번째 줄에 들어갈 내용을 입력 (11자 이내)',
          maxLength: 11,
          color: '#FFFE70',
          border: 'text-border-black',
          weight: '800',
          size: '30px',
          font: 'Tenada',
        },
        position: '60%',
      },
    ],
    opacity: '0.3',
    border: { weight: '2px', color: '#FFFFFF' },
  },
  thumbnail_05: {
    name: 'thumbnail_05',
    img_url: 'https://pub.angelleague.io/fast_cut/thumbnail/thumbnail05.png',
    titleList: [
      {
        title: {
          titleName: 'title1',
          titleContent: '영상썸네일스타일내용을입',
          placeholder: '첫 번째 줄에 들어갈 내용을 입력 (14자 이내)',
          maxLength: 14,
          color: '#FFFFFF',
          weight: '500',
          size: '30px',
          font: '양진체',
          align: 'start',
          letter: '-1.32px',
          shadow: '0px 0px 20px #000000',
        },
        position: '55%',
      },
      {
        title: {
          titleName: 'title2',
          titleContent: '력하시면미리보기할수있어요',
          placeholder: '두 번째 줄에 들어갈 내용을 입력 (15자 이내)',
          maxLength: 15,
          color: '#C1F950',
          weight: '500',
          size: '30px',
          font: '양진체',
          align: 'start',
          letter: '-1.32px',
          shadow: '0px 0px 5.28px #000000',
        },
        position: '75%',
      },
    ],
    bg_gradient:
      'linear-gradient(to bottom,rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))',
    border: { weight: '1px', color: '#FFFFFF' },
  },
  thumbnail_06: {
    name: 'thumbnail_06',
    img_url: 'https://pub.angelleague.io/fast_cut/thumbnail/thumbnail06.png',
    titleList: [
      {
        title: {
          titleName: 'title1',
          titleContent: '영상썸네일스타일내용을입',
          placeholder: '첫 번째 줄에 들어갈 내용을 입력 (14자 이내)',
          maxLength: 14,
          color: '#FFFFFF',
          weight: '400',
          size: '28px',
          font: 'SBAggroB',
          align: 'start',
          letter: '-2.64px',
          shadow: '0px 0px 11.5px #000000',
        },
        position: '60%',
      },
      {
        title: {
          titleName: 'title2',
          titleContent: '력하시면미리보기할수있어요',
          placeholder: '두 번째 줄에 들어갈 내용을 입력 (15자 이내)',
          maxLength: 15,
          color: '#74FCFD',
          weight: '400',
          size: '28px',
          font: 'SBAggroB',
          align: 'start',
          letter: '-2.64px',
          shadow: '0px 0px 11.5px #000000',
        },
        position: '77.5%',
      },
    ],
    bg_gradient:
      'linear-gradient(to bottom,rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5),  rgba(0, 36, 91, 1))',
    border: { weight: '2.3px', color: '#FFFFFF' },
  },
  thumbnail_07: {
    name: 'thumbnail_07',
    img_url: 'https://pub.angelleague.io/fast_cut/thumbnail/thumbnail07.png',
    titleList: [
      {
        title: {
          titleName: 'title1',
          titleContent: '영상썸네일스타일',
          placeholder: '첫 번째 줄에 들어갈 내용을 입력 (10자 이내)',
          maxLength: 10,
          color: '#FFFFFF',
          weight: '900',
          size: '46px',
          font: 'TmonMonsori',
          align: 'start',
          shadow: '0px 0px 8.44px #000000',
          letter: '-2.11px',
        },
        position: '58%',
      },
      {
        title: {
          titleName: 'title2',
          titleContent: '내용을입력하시면미리보기할수있어요',
          placeholder: '두 번째 줄에 들어갈 내용을 입력 (19자 이내)',
          maxLength: 19,
          color: '#171717',
          weight: '900',
          size: '21px',
          bg: '#F5C944',
          font: 'TmonMonsori',
          align: 'start',
          letter: '-1.32px',
          padding: '0 5px',
        },
        position: '78%',
      },
    ],
    bg_gradient:
      'linear-gradient(to bottom,rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))',
  },
  thumbnail_08: {
    name: 'thumbnail_08',
    img_url: 'https://pub.angelleague.io/fast_cut/thumbnail/thumbnail08.png',
    titleList: [
      {
        title: {
          titleName: 'title1',
          titleContent: '영상썸네일스타일보기',
          placeholder: '첫 번째 줄에 들어갈 내용을 입력 (10자 이내)',
          maxLength: 10,
          color: '#FFFFFF',
          weight: '800',
          size: '26px',
          font: 'SF_HambakSnow',
          letter: '-1.32px',
          shadow: '0px 0px 10.56px #000000',
        },
        position: '25%',
      },
      {
        title: {
          titleName: 'title2',
          titleContent: '내용을입력하시면',
          placeholder: '두 번째 줄에 들어갈 내용을 입력 (10자 이내)',
          maxLength: 10,
          color: '#FFFFFF',
          weight: '800',
          size: '26px',
          font: 'SF_HambakSnow',
          letter: '-1.32px',
          shadow: '0px 0px 10.56px #000000',
        },
        position: '40%',
      },
      {
        title: {
          titleName: 'title3',
          titleContent: '미리보기할수있어요',
          placeholder: '세 번째 줄에 들어갈 내용을 입력 (11자 이내)',
          maxLength: 11,
          color: '#FFFFFF',
          weight: '800',
          size: '26px',
          font: 'SF_HambakSnow',
          letter: '-1.32px',
          shadow: '0px 0px 10.56px #000000',
        },
        position: '55%',
      },
    ],
    opacity: '0.2',
  },
  thumbnail_09: {
    name: 'thumbnail_09',
    img_url: 'https://pub.angelleague.io/fast_cut/thumbnail/thumbnail09.png',
    titleList: [
      {
        title: {
          titleName: 'title1',
          titleContent: '영상썸네일스타일내용을입력하시면',
          placeholder: '첫 번째 줄에 들어갈 내용을 입력 (18자 이내)',
          maxLength: 18,
          color: '#FFFFFF',
          weight: '800',
          size: '23.6px',
          letter: '-1.32px',
          font: 'Tenada',
          align: 'start',
          shadow: '0px 0px 5.28px #000000',
        },
        position: '62%',
      },
      {
        title: {
          titleName: 'title2',
          titleContent: '미리보기할수있어요',
          placeholder: '두 번째 줄에 들어갈 내용을 입력 (11자 이내)',
          maxLength: 11,
          color: 'transparent',
          weight: '800',
          size: '42px',
          letter: '-1.91px',
          font: 'Tenada',
          align: 'start',
          height: '42px',
          gradient: {
            background:
              'linear-gradient(to bottom, rgba(255, 248, 75, 1), rgba(249, 47, 38, 1))',
            text: 'text',
          },
        },
        position: '74%',
      },
    ],
    bg_gradient:
      'linear-gradient(to bottom,rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))',
    outline_border: { weight: '5px', color: 'rgba(224, 39, 31, 1)' },
  },
  thumbnail_10: {
    name: 'thumbnail_10',
    img_url: 'https://pub.angelleague.io/fast_cut/thumbnail/thumbnail10.png',
    titleList: [
      {
        title: {
          titleName: 'title1',
          titleContent: '영상썸네일스타일보기',
          font: 'yg-jalnan',
          placeholder: '첫 번째 줄에 들어갈 내용을 입력 (10자 이내)',
          maxLength: 10,
          color: '#FFFE70',
          weight: '400',
          size: '27px',
          border: 'text-border-black',
          align: 'start',
          letter: '-1.58px',
        },
        position: '58%',
      },
      {
        title: {
          titleName: 'title2',
          titleContent: '내용을입력하셔서미리보기하세요',
          placeholder: '두 번째 줄에 들어갈 내용을 입력 (17자 이내)',
          maxLength: 17,
          font: 'yg-jalnan',
          color: '#FFFFFF',
          weight: '400',
          size: '21.5px',
          border: 'text-border-black',
          align: 'start',
          letter: '-1.58px',
        },
        position: '77.5%',
      },
    ],
    blank_box: true,
  },
}

interface PointHistory {
  type: string
  date: string
  point: number
}

export const points: PointHistory[] = [
  { type: '플랜결제', date: '2024.05.28', point: 10000 },
  { type: '플랜결제', date: '2024.05.29', point: 20000 },
  { type: '플랜결제', date: '2024.05.30', point: 30000 },
  { type: '플랜결제', date: '2024.05.31', point: 40000 },
]

export const dailyDummyData = [
  { usageDate: '10', usedCount: 12 },
  { usageDate: '10', usedCount: 41 },
  { usageDate: '10', usedCount: 55 },
  { usageDate: '10', usedCount: 11 },
  { usageDate: '10', usedCount: 66 },
  { usageDate: '10', usedCount: 77 },
  { usageDate: '10', usedCount: 76 },
  { usageDate: '10', usedCount: 10 },
  { usageDate: '10', usedCount: 35 },
  { usageDate: '10', usedCount: 72 },
  { usageDate: '10', usedCount: 10 },
  { usageDate: '10', usedCount: 34 },
  { usageDate: '10', usedCount: 13 },
  { usageDate: '10', usedCount: 33 },
  { usageDate: '10', usedCount: 10 },
  { usageDate: '10', usedCount: 45 },
  { usageDate: '10', usedCount: 10 },
  { usageDate: '10', usedCount: 80 },
  { usageDate: '10', usedCount: 10 },
  { usageDate: '10', usedCount: 14 },
  { usageDate: '10', usedCount: 12 },
  { usageDate: '10', usedCount: 10 },
  { usageDate: '10', usedCount: 10 },
  { usageDate: '10', usedCount: 56 },
  { usageDate: '10', usedCount: 72 },
  { usageDate: '10', usedCount: 10 },
  { usageDate: '10', usedCount: 67 },
  { usageDate: '10', usedCount: 66 },
  { usageDate: '10', usedCount: 5 },
  { usageDate: '10', usedCount: 45 },
  { usageDate: '10', usedCount: 10 },
  { usageDate: '10', usedCount: 44 },
]
