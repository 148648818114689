import { UseQueryResult, useMutation, useQuery } from '@tanstack/react-query'
import { confirmPoint } from '../../../service/point'

function useConfirmPoint() {
  return useMutation({
    mutationKey: ['confirm-point'],
    mutationFn: confirmPoint,
  })
}

export default useConfirmPoint
