import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState,
} from 'react'
import { VoiceGroupFactor } from '../../service/list'
interface Props {
  voiceTooltip: boolean
  setVoiceTooltip: Dispatch<SetStateAction<boolean>>
  voiceList: VoiceGroupFactor[]
}

const VoiceTooltip = ({
  children,
  voiceTooltip,
  setVoiceTooltip,
  voiceList,
}: PropsWithChildren<Props>) => {
  const light = voiceList.find((voice) => voice.planName === '라이트')
  const pro = voiceList.find((voice) => voice.planName === '프로')
  const pre = voiceList.find((voice) => voice.planName === '프리미엄')

  return (
    <>
      {' '}
      <img
        src="assets/img/icon_tooltip.png"
        alt="툴팁"
        className=" w-[20px] h-[20px]"
        onMouseEnter={() => {
          setVoiceTooltip(true)
        }}
      />
      {voiceTooltip && (
        <div className="flex flex-col p-[16px] md:p-[32px] gap-[10px] bg-white w-[200px] md:w-[366px] h-auto absolute border-[3px] border-main  rounded-[20px] bottom-[20px] left-20">
          <p className="font-bold md:text-[24px] text-base flex items-center justify-between ">
            목소리란?{' '}
            <button
              onClick={() => {
                setVoiceTooltip(false)
              }}
            >
              <img
                src="assets/img/Icon_x.png"
                alt="icon_x"
                className="md:w-[40px] md:h-[40px] w-[25px] h-[25px]"
              />
            </button>
          </p>
          <p className=" md:text-base text-[10px]">
            정리된 컨텐츠 내용을 나레이션 하는 AI 목소리를 말해요. 목소리는 AI
            퀄리티에 따라 총 3단계의 가격이 책정되어 있어요. 미리듣기로
            들어보시고 나의 숏폼에 어울리는 목소리를 선택하세요!
          </p>
          <div className="flex flex-col">
            <p className="text-[14px] text-error">할인 이벤트중</p>
            <p className="flex items-center">
              <img
                src="assets/img/cash.png"
                alt="point"
                width={11}
                height={13}
              />
              <s className="text-[#5E6068]  ml-2">{`${light?.price}`}캐시</s>
              <span className="text-main ml-2">
                {' '}
                {`${light?.originPrice}`}캐시
              </span>
            </p>
            <p className="flex items-center">
              <img
                src="assets/img/cash.png"
                alt="point"
                width={11}
                height={13}
              />
              <img
                src="assets/img/cash.png"
                alt="point"
                width={11}
                height={13}
              />
              <s className="text-[#5E6068]  ml-2">{`${pro?.price}`}캐시</s>
              <span className="text-main ml-2">
                {' '}
                {`${pro?.originPrice}`}캐시
              </span>
            </p>
            <p className="flex items-center">
              <img
                src="assets/img/cash.png"
                alt="point"
                width={11}
                height={13}
              />
              <img
                src="assets/img/cash.png"
                alt="point"
                width={11}
                height={13}
              />
              <img
                src="assets/img/cash.png"
                alt="point"
                width={11}
                height={13}
              />
              <s className="text-[#5E6068]  ml-2">{`${pre?.price}`}캐시</s>
              <span className="text-main ml-2">
                {' '}
                {`${pre?.originPrice}`}캐시
              </span>
            </p>
          </div>
        </div>
      )}
    </>
  )
}

export default VoiceTooltip
