import React, { useEffect, useState } from 'react'
import { My } from '../components/My'
import { ShadowBox } from '../components/ShadowBox'
import { isMobile } from 'react-device-detect'
import { useLocation, useNavigate } from 'react-router-dom'
import useGetMePlan from '../hooks/query/useGetMePlan'

import { FastCut, UsageRequest, UsageRequestFactor } from '../service/list'
import useGetUsageRequest from '../hooks/query/useGetUsageRequest'

import useUserStore from '../store/useUserStore'

import { getUtmLocalStorage, showErrorBox, UserType } from '../utils/common'
import { GuideToUse } from '../components/GuideToUse'
import classNames from 'classnames'
import { Button } from '../components/atom/Button'
import { toast, ToastContainer } from 'react-toastify'
import { points } from '../dummy/data'
import { CashPaymentModal } from '../components/CashPaymentModal'
import { useGetPointOrderId } from '../hooks/query/point/usetGetPoinOrderId'
import { RequestPurchasePoint } from '../service/point'
import useConfirmPayment from '../hooks/query/useConfirmPayment'
import { Spinner } from '../components/atom/Spinner'
import useConfirmPoint from '../hooks/query/point/useConfirmPoint'
import { useGetMyPointHistory } from '../hooks/query/point/useGetMyPointHistory'
import { useGetMyPoint } from '../hooks/query/point/useGetMyPoint'
import { useGetMySpendingHistory } from '../hooks/query/point/useGetMySpendingHistory'
import { useGetMyPointTotal } from '../hooks/query/point/useGetMyPointTotal'
import ConfirmPaymentCashModal from '../components/ConfirmPaymentCashModal'
import { pointPurchaseBtnClick, purchasePoint } from '../utils/metaAd'
import { useSaveUtm } from '../hooks/query/useSaveUtm'

export const MyCashDashboard = () => {
  const { mutate: saveUtmMutate } = useSaveUtm()
  const urlParams = new URLSearchParams(window.location.search)
  const paymentKey = urlParams.get('paymentKey')
  const orderId = urlParams.get('orderId')
  const amount = urlParams.get('amount')
  const pType = urlParams.get('pType')
  const promotionCode = urlParams.get('promotionCode')
  const valid = urlParams.get('valid')
  const bonus = urlParams.get('bonus')

  const POINT_HISTORY = 'pointHistory'
  const SPENDING_HISTORY = 'spendingHistory'
  const location = useLocation()
  const navigate = useNavigate()

  const [totalItems, setTotalItems] = useState(0)
  const [amountValue, setAmountValue] = useState(0)
  const [bonusValue, setBonusValue] = useState(0)
  const itemsPerPage = 30
  const [defaultPage, setDefaultPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [requestPurchasePoint, setRequestPurchasePoint] =
    useState<RequestPurchasePoint>({ orderId: '' })
  const totalPages = Math.ceil(totalItems / itemsPerPage)

  const params = new URLSearchParams(location.search)
  let page = parseInt(params.get('page') || '', 10)
  if (!page || isNaN(page) || page < 1) {
    page = defaultPage
    params.set('page', page.toString())
    params.set('pageSize', itemsPerPage.toString())
    navigate({ search: params.toString() })
  }

  const { data: myPointHistorydata, refetch: getMyPointHistoryRefetch } =
    useGetMyPointHistory(page, itemsPerPage)
  const { data: mySpendingHistorydata, refetch: getMySpendingHistoryRefetch } =
    useGetMySpendingHistory(page, itemsPerPage)
  const { data: myPointData, refetch: getMyPointRefetch } = useGetMyPoint()
  const { data: myPointTotalData, refetch: getMyPointTotalRefetch } =
    useGetMyPointTotal()

  const { mutate: confirmPointMutate, isPending } = useConfirmPoint()
  const { mutate: pointOrderIdMutate } = useGetPointOrderId()
  // user의 영상생성 개수 불러오기
  const userInfo = useUserStore((state: any) => state.userInfo)

  const [tab, setTab] = useState(SPENDING_HISTORY)
  const [cashPaymentModal, setCashPaymentModal] = useState(false)
  const [isModal, setModal] = useState<boolean>(false)

  const handleCashPaymentModal = () => {
    pointOrderIdMutate(undefined, {
      onSuccess: (data) => {
        setRequestPurchasePoint(data)
        setCashPaymentModal(true)
      },
      onError: () => {},
    })
  }
  const renderPageNumbers = () => {
    const pagesPerBlock = 10
    const pageNumbers = []
    const currentBlock = Math.floor((currentPage - 1) / pagesPerBlock)
    const startPage = currentBlock * pagesPerBlock + 1
    const endPage = Math.min(startPage + pagesPerBlock - 1, totalPages)

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          className={classNames(
            `flex items-center justify-center w-[32px] h-[32px] border-[1px]  font-bold text-[14px] rounded-[4px]`,
            {
              'border-main text-main': currentPage === i,
              'border-[#DFE3E8]': currentPage !== i,
            }
          )}
          key={i}
          onClick={() => handlePageChange(i)}
          disabled={currentPage === i}
        >
          {i}
        </button>
      )
    }

    return pageNumbers
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    const params = new URLSearchParams(location.search)
    params.set('page', page.toString())
    params.set('pageSize', itemsPerPage.toString())
    navigate({ search: params.toString() })
  }
  const handlePageEndChange = (page: number) => {
    setCurrentPage(page)
    const params = new URLSearchParams(location.search)
    params.set('page', page.toString())
    params.set('pageSize', itemsPerPage.toString())
    navigate({ search: params.toString() })
  }

  const emptyContent = () => {
    const pointHistorys = myPointHistorydata?.savePointList
    const spendingHistorys = mySpendingHistorydata?.exceptSavePointList
    if (tab === POINT_HISTORY) {
      return !pointHistorys.length ? (
        <>
          {' '}
          <>
            <div className="flex flex-col h-[372px] items-center  font-bold">
              <div className=" w-full h-[175px] flex justify-center items-center">
                {' '}
                <p className="text-[18px] text-center">
                  아직 충전 내역이 없어요!
                  <br />
                  캐시를 충전해서 숏폼을 생성해보세요!
                </p>
              </div>

              <Button
                className=" rounded-[40px] bg-main  h-[46px]  text-white  font-bold mt-[16px] px-[30.5px]"
                onClick={handleCashPaymentModal}
              >
                캐시 충전
              </Button>
            </div>
          </>{' '}
        </>
      ) : (
        <>
          <div className="flex flex-col  w-full h-auto items-center mt-[16px] gap-[16px] ">
            {pointHistorys.map((el) => {
              return (
                <div className="flex flex-col w-full">
                  <div className="flex justify-between items-center w-full">
                    <div className="flex flex-col justify-between gap-[8px]">
                      <p className="font-bold  md:text-base text-[14px]">
                        {el.reasonTypeName}
                      </p>
                      <p className="text-[#8F929B] md:text-base text-[14px]">
                        {el.createAt} ~ {el.expireAt}
                      </p>
                    </div>
                    <div>
                      <p className="font-bold  md:text-[18px] text-[16px]">
                        {Number(el.amount).toLocaleString()}
                      </p>
                    </div>
                  </div>
                  <hr className="w-full h-[1px] bg-[#EEF1F7] mt-[16px]" />
                </div>
              )
            })}
          </div>
        </>
      )
    } else if (tab === SPENDING_HISTORY) {
      return !spendingHistorys.length ? (
        <>
          {' '}
          <>
            <div className="flex flex-col h-[372px] items-center  font-bold">
              <div className=" w-full h-[175px] flex justify-center items-center">
                <p className="text-[18px] text-center leading-[28px] ">
                  아직 사용 내역이 없어요. <br />
                  충전된 캐시는 숏폼 생성할 때 사용해주세요!
                </p>
              </div>

              <Button
                className="w-[192px] h-[46px] rounded-[40px] bg-main text-white font-bold mt-[16px]"
                onClick={() => {
                  navigate('/short-video-editor')
                }}
              >
                숏폼 만들러 가기
              </Button>
            </div>
          </>
        </>
      ) : (
        <>
          <div className="flex flex-col  w-full h-auto items-center mt-[16px] gap-[16px]">
            {spendingHistorys.map((el) => {
              return (
                <div className="flex flex-col w-full">
                  <div className="flex justify-between items-center w-full">
                    <div className="flex flex-col justify-between gap-[8px]">
                      <p className="font-bold md:text-base text-[14px]">
                        {el.typeName}
                      </p>
                      <p className="text-[#8F929B] md:text-base text-[14px]">
                        {el.createAt}
                      </p>
                    </div>
                    <div>
                      <p className="font-bold md:text-[18px] text-[16px]">
                        {Math.abs(Number(el.amount)).toLocaleString()}
                      </p>
                    </div>
                  </div>
                  <hr className="w-full h-[1px] bg-[#EEF1F7] mt-[16px]" />
                </div>
              )
            })}
          </div>
        </>
      )
    }
  }

  useEffect(() => {
    if (myPointHistorydata && tab === POINT_HISTORY) {
      setTotalItems(Number(myPointHistorydata.savePointListTotalCount))
    }
    if (mySpendingHistorydata && tab === SPENDING_HISTORY) {
      setTotalItems(Number(mySpendingHistorydata.exceptSavePointListTotalCount))
    }
  }, [myPointHistorydata, mySpendingHistorydata, tab])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const page = parseInt(params.get('page') || '', 10)

    if (amount && pType && bonus && pType === 'success') {
      setAmountValue(Number(amount) * (10 / 11))
      if (bonus) {
        setBonusValue(Number(bonus))
      }
      setModal(true)
    }
    if (page && tab === POINT_HISTORY) {
      setCurrentPage(page)
      getMyPointHistoryRefetch()
    } else if (page && tab === SPENDING_HISTORY) {
      setCurrentPage(page)
      getMySpendingHistoryRefetch()
    }

    getMyPointRefetch()
  }, [location.search])

  useEffect(() => {
    let page = 1
    params.set('type', tab === POINT_HISTORY ? 'buy' : 'spent')
    params.set('page', page.toString())
    params.set('pageSize', itemsPerPage.toString())
    navigate({ search: params.toString() })
  }, [tab])

  useEffect(() => {
    if (paymentKey && orderId && amount) {
      const confirmPaymentParams = {
        paymentKey,
        orderId,
        amount: Number(amount),
      }
      confirmPointMutate(confirmPaymentParams, {
        onSuccess: (data: any) => {
          const utmData = getUtmLocalStorage()
          if (utmData?.utmSource) {
            utmData.trackType = 'PURCHASE_POINT'
            saveUtmMutate(utmData, {
              onSuccess: () => {},
              onError: () => {},
            })
            purchasePoint()
          } else {
            pointPurchaseBtnClick('dashboard')
          }

          navigate(
            `/my-cash-dashboard?amount=${amount}&pType=success&valid=${valid}&promotionCode=${promotionCode}&bonus=${bonus}`
          )
        },
        onError: (e) => {
          console.log(e)
          navigate(`/payment-fail?path=my-cash-dashboard`)
        },
      })
    }
  }, [paymentKey, orderId, amount])

  useEffect(() => {
    if (cashPaymentModal || isPending) {
      document.body.classList.add('overflow-hidden') // Tailwind 유틸리티 클래스 사용
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [cashPaymentModal, isPending])

  return (
    <div className=" md:w-[816px] w-[375px] min-h-[100vh] my-0 mx-auto flex flex-col px-[16px]">
      <ToastContainer />
      <div>
        <My>
          <p>캐시 관리</p>
        </My>
        <div className="flex justify-center relative mt-6">
          <p className="font-bold text-[24px] md:text-[48px] ">캐시 관리</p>
          <p className="absolute -right-1 md:right-0 flex items-center h-full justify-center  ">
            <Button
              className="text-white md:w-[128px] w-[80px] bg-main rounded-[100px] md:h-[46px] h-[38px] font-bold md:text-base text-[13px]"
              onClick={handleCashPaymentModal}
            >
              캐시 충전
            </Button>
          </p>
        </div>
      </div>

      <div className="point bg-[#F6F8FB] rounded-[20px] md:rounded-[40px] md:py-[32px] md:px-[40px] p-5 mt-[24px] ">
        <div className="flex justify-between ">
          <div className="flex justify-between items-center font-bold  w-full">
            <p className="md:text-[24px] text-[18px]">보유 캐시</p>
            <p className="md:text-[32px] text-[18px] text-main ">
              {myPointData.totalPoint.toLocaleString()}
            </p>
          </div>
        </div>
        <div className="flex font-bold text-[14px] md:text-[18px] mt-[16px] items-center">
          <div className="px-[2px] h-[60px] bg-[#D4D9DF]"></div>
          <div className=" w-full flex flex-col">
            <div className="w-full flex justify-between">
              <p className="ml-4">구매 캐시</p>
              <p>{myPointData.purchasePoint.toLocaleString()}</p>
            </div>
            <div className="w-full flex justify-between md:mt-[16px] mt-2">
              <p className="ml-4">보너스 캐시</p>
              <p>{myPointData.activityPoint.toLocaleString()}</p>
            </div>
          </div>
        </div>
        <div className="flex font-bold text-[12px] md:text-[14px] mt-2 md:mt-[16px] items-center">
          <div className=" w-full flex flex-col">
            <div className="w-full flex justify-between">
              <p className="ml-4 text-[#FD6767]">오늘 만료 예정</p>
              <p>{myPointTotalData?.expiringPointToday.toLocaleString()}</p>
            </div>
            <div className="w-full flex justify-between mt-1">
              <p className="ml-4 text-[#FF8800]">내일 만료 예정</p>
              <p>{myPointTotalData?.expiringPoint1Day.toLocaleString()}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="md:border  md:border-[#EEF1F7] md:p-[40px] md:shadow-main flex flex-col bg-[#FFFFFF]  min-h-[508px]  mt-[32px] p-1 rounded-[40px]">
        <div className="point-content flex gap-[16px] font-bold  md:mb-[24px] text-[18px]">
          <button
            className={classNames('', {
              'text-main border-b-[4px] border-main ': tab === SPENDING_HISTORY,
            })}
            onClick={() => {
              setTab(SPENDING_HISTORY)
            }}
          >
            사용 내역
          </button>
          <button
            className={classNames('', {
              'text-main border-b-[4px] border-main': tab === POINT_HISTORY,
            })}
            onClick={() => {
              setTab(POINT_HISTORY)
            }}
          >
            충전 내역
          </button>
        </div>
        {emptyContent()}
        <div className="page flex flex-col gap-[16px] justify-center items-center mt-4">
          <div className="flex justify-center gap-[4px] ">
            <button
              className="flex items-center justify-center w-[32px] h-[32px] border-[1px] border-[#DFE3E8] font-bold text-[14px] disabled:bg-[#EEF1F7] rounded-[4px]"
              onClick={() => handlePageEndChange(1)}
              disabled={currentPage === 1}
            >
              <img src="assets/img/icon_arrow_left_end.png" alt="icon" />
            </button>
            <button
              className="flex items-center justify-center w-[32px] h-[32px] border-[1px] border-[#DFE3E8] font-bold text-[14px] disabled:bg-[#EEF1F7] rounded-[4px]"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <img src="assets/img/icon_arrow_left.png" alt="icon" />
            </button>
            {renderPageNumbers()}
            <button
              className="flex items-center justify-center w-[32px] h-[32px] border-[1px] border-[#DFE3E8] font-bold text-[14px] disabled:bg-[#EEF1F7] rounded-[4px]"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <img src="assets/img/icon_arrow_right.png" alt="icon" />
            </button>
            <button
              className="flex items-center justify-center w-[32px] h-[32px] border-[1px] border-[#DFE3E8] font-bold text-[14px] disabled:bg-[#EEF1F7] rounded-[4px]"
              disabled={currentPage === totalPages}
              onClick={() => {
                handlePageEndChange(totalPages)
              }}
            >
              <img src="assets/img/icon_arrow_right_end.png" alt="icon" />
            </button>
          </div>
        </div>
      </div>
      {cashPaymentModal && (
        <CashPaymentModal
          setCashPaymentModal={setCashPaymentModal}
          affiliatePoint={userInfo?.affiliate?.point}
          userInfo={userInfo?.userInfo}
          userCurrentPoint={myPointData.totalPoint}
          requestPurchasePoint={requestPurchasePoint}
        />
      )}
      {isPending && (
        <>
          {' '}
          <Spinner />
        </>
      )}
      {isModal && (
        <ConfirmPaymentCashModal
          isModal={isModal}
          setModal={setModal}
          amount={amountValue}
          bonusValue={bonusValue}
          valid={valid ? valid : ''}
          promotionCode={promotionCode ? Number(promotionCode) : 0}
        />
      )}
    </div>
  )
}
