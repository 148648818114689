import { UseQueryResult, useMutation, useQuery } from '@tanstack/react-query'
import { getList, postList } from '../../service/list'
import { authGoogle } from '../../service/auth'
import { acceptedTerms, getProfile } from '../../service/user'

function useTerms() {
  return useMutation({
    mutationKey: ['terms'],
    mutationFn: acceptedTerms,
  })
}

export default useTerms
