import React, { PropsWithChildren } from 'react'
import { handleClickPromotionBanner } from '../../utils/affiliate'
import { useNavigate } from 'react-router-dom'
interface PlanPromotionBannerProps {
  daysLeft: number
  hoursLeft: number
  affiliateImage: string
  affiliateDescription: string
  description: string
}
export const PlanPromotionBanner = ({
  daysLeft,
  hoursLeft,
  affiliateImage,
  affiliateDescription,
  description,
}: PropsWithChildren<PlanPromotionBannerProps>) => {
  const navigate = useNavigate()
  return (
    <div
      className="flex flex-col items-center cursor-pointer mt-5"
      onClick={() => {
        handleClickPromotionBanner(navigate)
      }}
    >
      <p className="font-bold text-[24px] flex items-center gap-2 md:flex-row flex-col ">
        <span className="flex items-center">
          {' '}
          <img
            src={affiliateImage}
            alt="logo"
            className="w-[33px] h-[33px] mr-1"
          />
          {affiliateDescription}
        </span>
        <span className="text-[#464DEE]">
          제휴 혜택 {`${daysLeft}`}일 남았어요
        </span>
      </p>
      <p className="font-bold md:w-auto w-[335px] md:text-[18px] text-[13px] bg-[#4360EC] rounded-[40px] text-[white] md:px-4 py-1 text-center mt-1">
        {description}
      </p>
    </div>
  )
}
