import { useEffect, useState } from 'react'
import { ShadowBox } from '../components/ShadowBox'
import useUserStore from '../store/useUserStore'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import useGetMakeTotal from '../hooks/query/useGetMakeTotal'
import CountUp from 'react-countup'
import { Button } from '../components/atom/Button'
import { FAQ } from '../components/FAQ'
import { FreePlanModal } from '../components/FreePlanModal'
import { usePostExperiencePlan } from '../hooks/query/usePostExperiencePlan'
import { Modal } from '../components/layout/Modal'
import {
  getUtmLocalStorage,
  renderPaybackHome,
  renderUserModalsByHome,
  renderUserModalsByMyVideo,
  UserType,
} from '../utils/common'
import { FreeButton } from '../components/atom/FreeButton'
import classNames from 'classnames'
import { HomePopup } from '../components/popup/HomePopup'
import useGetPopupContent from '../hooks/query/useGetPopupContent'
import {
  adFreeExperienceBtnClick,
  freeExperienceBtnClick,
  signUpBtnClick,
} from '../utils/metaAd'
import { useSaveUtm } from '../hooks/query/useSaveUtm'
import { useCookies } from 'react-cookie'
import { FreeDefaultModal } from '../components/freePlan/WelcomeBox'

const Home = () => {
  const [cookies, setCookies] = useCookies(['fast_cut_popup'])
  const urlParams = new URLSearchParams(window.location.search)
  const userType = urlParams.get('userType')
  const { mutate: saveUtmMutate } = useSaveUtm()
  const { mutate: experiencePlanMutate } = usePostExperiencePlan()
  const { data: popupContent } = useGetPopupContent()
  const [numberStatus, setNumberStatus] = useState(5)
  const navigate = useNavigate()
  const userInfo = useUserStore((state: any) => state.userInfo)
  const [buttonPosition, setButtonPosition] = useState('bottom')
  const [isFreePlanModal, SetFreePlanModal] = useState<boolean>(false)
  const [isPopupModal, setPopupModal] = useState<boolean>(true)
  const [isPlan, setIsPlan] = useState<boolean>(false)
  const [isInAppBrowserModal, setInAppBrowserModal] = useState<boolean>(false)
  const [isStatus, setStatus] = useState<boolean>(false)

  const [featureList, setFeatureList] = useState([
    '사용 플랜 선택',
    'AI 대본 컨셉 선택',
    'AI 나레이션 말투 선택',
    '썸네일, 목소리 선택',
    '배경음악 선택',
    '화면전환 효과 선택',
    '패스트컷 워터마크 삭제',
  ])

  const [totalCount, setTotalCount] = useState(0)
  const { data: _totalCount } = useGetMakeTotal()

  const paymentFreePlan = () => {
    if (!userInfo?.userInfo) {
      navigate('/sign-up')
      return
    } else if (userInfo && userInfo?.userType === UserType.체험) {
      navigate('/video-editor')
      return
    }

    const utmData = getUtmLocalStorage()
    if (utmData?.utmSource) {
      utmData.trackType = 'FREE_EXPERIENCE'
      saveUtmMutate(utmData, {
        onSuccess: () => {},
        onError: () => {},
      })
      adFreeExperienceBtnClick()
    } else {
      freeExperienceBtnClick()
    }

    experiencePlanMutate('', {
      onSuccess: (data) => {
        navigate('/video-editor')
      },
      onError: () => {
        setIsPlan(true)
      },
    })
  }

  const handleOpenExternalBrowser = () => {
    const url = window.location.href // 현재 페이지 URL
    const userAgent: string =
      navigator.userAgent || navigator.vendor || (window as any).opera
    if (/android/i.test(userAgent)) {
      // 크롬에서 열기 위한 intent URL
      const intentUrl = `intent://${url.replace(
        /^https?:\/\//,
        ''
      )}#Intent;scheme=https;package=com.android.chrome;end;`
      window.location.href = intentUrl // 외부 브라우저로 열기
    } else {
      setInAppBrowserModal(false)
    }
    //window.open(url, '_blank') // 새 창으로 외부 브라우저에서 열기
  }

  useEffect(() => {
    if (_totalCount !== undefined) {
      setTotalCount(_totalCount)
    }
  }, [_totalCount])

  const onClickTextArea = (pathName: string) => {
    if (userInfo) {
      navigate(pathName)
    } else {
      navigate('/sign-up')
    }
  }
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight
      const documentHeight = document.documentElement.scrollHeight
      const offset = 100

      if (scrollPosition >= documentHeight - offset) {
        setButtonPosition('up')
      } else {
        setButtonPosition('bottom')
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [buttonPosition])

  useEffect(() => {
    if (isFreePlanModal) {
      document.body.classList.add('overflow-hidden') // Tailwind 유틸리티 클래스 사용
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [isFreePlanModal])

  return (
    <div className="pt-[45px] md:max-w-[816px] max-w-[375px] my-0 mx-auto flex flex-col items-center ">
      {/* 홈화면 첫번째 */}
      <div className="flex flex-col gap-[20px] w-[343px] md:w-[816px]">
        <div className="flex flex-col items-center">
          <p className="md:text-[60px] text-[40px]">상품 링크만 있으면,</p>
          <p
            className="md:text-[64px] text-[48px] font-[800] text-center"
            style={{
              background: 'linear-gradient(90deg, #00D2D4, #4360EC)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            AI가 만들어주는 상품 리뷰 롱폼!
          </p>
          <p className="text-[18px] font-bold mt-6">
            클릭 한 번에 상품 리뷰 영상 유튜브 등록!
          </p>
          {!isMobile && (
            <p className="mt-6">
              <img
                src="assets/img/icon_youtube.png"
                alt="youtube"
                width={66}
                height={66}
              />
            </p>
          )}
          <div className="flex flex-col items-center md:text-base mt-6 relative text-base">
            {!isMobile && (
              <img
                src="assets/img/person.png"
                alt="person"
                className=" absolute w-[150px] h-[150px] md:w-[179px] md:h-[193px] bottom-0 -right-44"
              />
            )}
            <div className="flex items-center gap-[4px]">
              <span className="flex items-center gap-[1px]">
                {' '}
                <img
                  src="assets/img/coupang_icon.png"
                  alt="coupang"
                  width={17.76}
                  height={17.42}
                />
                쿠팡 파트너스
              </span>
              <span className="flex items-center gap-[1px]">
                {' '}
                <img
                  src="assets/img/ali_icon.png"
                  alt="ali"
                  width={17.76}
                  height={17.42}
                />
                알리 어필리에이트
              </span>

              <p> 링크만 있으면</p>
            </div>
            <p className="">
              <span className=" font-bold"> FASTCUT AI </span>가 나레이션, 편집,
              BGM 넣은 영상을
            </p>
            <p>내 채널에 자동 등록까지 해줘요!</p>
          </div>
          {isMobile && (
            <p className="mt-6  flex items-end">
              <img
                src="assets/img/person.png"
                alt="person"
                className=" w-[176px] h-[191px] bottom-0 left-0"
              />
              <p className="w-[53px] h-[53px] flex items-end">
                {' '}
                <img
                  src="assets/img/icon_youtube.png"
                  alt="youtube"
                  width={53}
                  height={53}
                />
              </p>
            </p>
          )}
        </div>
      </div>
      <div className="statusCnt flex flex-col items-center gap-[16px] w-[343px] md:w-[816px] my-[80px] md:my-[80px]">
        <div>
          <p className="text-[34px] md:text-[40px] text-main font-bold text-center">
            패스트컷 AI로 생성된 {isMobile && <br />} 리뷰영상
          </p>
        </div>
        <div>
          <p className="text-[64px] md:text-[96px] font-[800]">
            <span>
              <CountUp end={totalCount} duration={2} separator="," />
            </span>{' '}
            <span className=" text-[36px] md:text-[40px]">개</span>
          </p>
        </div>
      </div>
      <div className="home_fifth flex w-[100vw] flex-col md:flex-row  mb-[80px] gap-2 ">
        {' '}
        <div className="w-full md:w-[67%]">
          <video
            playsInline
            controls
            autoPlay
            muted
            className="border rounded-[10px] w-full"
            src="assets/video/final.mp4"
          />
        </div>
        <div className="flex flex-col w-full md:w-[33%] gap-2">
          <div className="">
            <video
              playsInline
              controls
              autoPlay
              muted
              className="border rounded-[10px]"
              src="assets/video/home2.mp4"
            />
          </div>
          <div className="">
            <video
              playsInline
              controls
              autoPlay
              muted
              className="border rounded-[10px]"
              src="assets/video/home3.mp4"
            />
          </div>
        </div>
      </div>
      <div className="md:mb-20 mb-10">{renderPaybackHome(userInfo)}</div>
      <div className="flex flex-col items-center gap-[16px] w-[343px] md:w-[816px] mb-[80px]">
        <div className="flex flex-col items-center gap-4">
          <p className="md:text-[48px] text-[34px] font-bold text-center">
            <span className="text-main ">패스트컷 AI</span>의{' '}
            {isMobile && <br />} 특별한 선택
          </p>
          <p className="md:text-[18px] text-base text-center">
            간단한 선택만으로 개성있는 결과물을 만들 수 있는 <br />
            패스트컷 AI의 프리미엄 영상 효과들을 사용해보세요!
          </p>
          <Button
            className={classNames(
              ' px-[23px] py-[13px] border-none rounded-[48px] text-white font-bold',
              {
                'bg-href': !userInfo,
                'bg-main': userInfo,
              }
            )}
            onClick={() => {
              onClickTextArea('/video-editor')
            }}
          >
            {userInfo ? '지금 시작하기 >' : `가입하고 쿠폰팩 뽑기`}
          </Button>
        </div>
        <div className="flex flex-col md:flex-row gap-2 ">
          <div className="first flex flex-col bg-[#F6F8FB] p-5 rounded-[40px] gap-2 w-full md:w-[33%]">
            <div className="flex flex-col gap-10">
              <div>
                <img src="assets/img/home/longform_1.png" alt="1" />
              </div>
              <div>
                <p className="font-bold text-[8px]">PREMIUM 1</p>
                <p className="text-[18px] font-bold">
                  썸네일 디자인을 선택하세요
                </p>
                <p className="mt-10">
                  10종류의 유튜브용 썸네일 디자인 중 마음에 드는 디자인을
                  선택해보세요!따로 그래픽 툴을 사용하지 않고도 눈에 띄는
                  썸네일을 만들 수 있어요.{' '}
                </p>
              </div>
            </div>
          </div>
          <div className="first flex flex-col bg-[#F6F8FB] p-5 rounded-[40px] gap-2 w-full md:w-[33%]">
            <div className="flex flex-col gap-10">
              <div>
                <img src="assets/img/home/longform_2.png" alt="1" />
              </div>
              <div>
                <p className="font-bold text-[8px]">PREMIUM 2</p>
                <p className="text-[18px] font-bold">목소리를 선택하세요</p>
                <p className="mt-10">
                  기본 목소리부터 프로, 프리미엄 목소리까지. 플랜에 따라 다양한
                  목소리 선택이 가능해요. 상품에 어울리는 목소리를 선택해서
                  영상의 퀄리티를 높이세요.
                </p>
              </div>
            </div>
          </div>
          <div className="first flex flex-col bg-[#F6F8FB] p-5 rounded-[40px] gap-2  w-full md:w-[33%]">
            <div className="flex flex-col gap-10">
              <div>
                <img src="assets/img/home/longform_3.png" alt="1" />
              </div>
              <div>
                <p className="font-bold text-[8px]">PREMIUM 3</p>
                <p className="text-[18px] font-bold">
                  나레이션 말투를 선택하세요
                </p>
                <p className="mt-10">
                  내 채널은 어떤 캐릭터를 가지고 있나요? 내가 리뷰하는 상품에는
                  어떤 말투가 어울릴까요? 아나운서, 블로거, 세일즈맨 등의 말투를
                  자유롭게 선택해보세요!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center gap-[40px] w-[343px] md:w-[816px]  mb-[80px] md:mb-[120px]">
        <div className="w-full flex items-center justify-center md:w-[50%]">
          <img
            src="assets/img/icon_ali_coupang.png"
            alt="ali_coupang"
            className=" h-[200px] md:h-[254px] w-[325px] md:w-[388px]"
          />
        </div>
        <div className="w-[343px] md:w-[50%] flex flex-col items-center md:items-start gap-[16px]">
          <p className="text-[34px] md:text-[40px] font-bold w-[343px] md:w-[388px] leading-[40.8px] md:leading-[48px]">
            <span className="text-main w-[388px]">
              쿠팡 파트너스, 알리익스프레스 어필리에이트
            </span>
            를<br /> 유튜브에서!
          </p>
          <p className="text-[#2E343D] leading-[21.6px]  md:text-[18px] w-[343px] md:-[388px]">
            블로그에서 눈치보며 리뷰하기 힘드셨죠?
            <br />
            유튜브도 해보고싶은데 영상 만들기 막막하시죠?
            <br />
            비교할 수 없을 규모의 사용자가 기다리고 있는 유튜브로 빠르고 편하게
            진출하세요!
          </p>
          <div className="flex justify-end md:justify-start w-full pr-[20px]">
            <Button
              className={classNames(
                ' px-[23px] py-[13px] border-none rounded-[48px] text-white font-bold',
                {
                  'bg-href': !userInfo,
                  'bg-main': userInfo,
                }
              )}
              onClick={() => {
                onClickTextArea('/video-editor')
              }}
            >
              {userInfo ? '지금 시작하기 >' : `가입하고 쿠폰팩 뽑기`}
            </Button>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-[16px] md:gap-[40px] w-[343px] md:w-[816px]  mb-[80px] md:mb-[120px]">
        <div className="md:w-[50%] flex flex-col gap-[16px]">
          <p className="md:text-[40px] text-[34px] md:leading-[48px] leading-[40px] font-bold">
            <span className="text-main ">패스트컷 AI</span>의<br />
            놀라운 결과물
          </p>
          <p className=" leading-[21.6px]">
            상품 링크만 입력하면,
            <br />
            인트로, 나레이션, 상품정보를 깔끔하게 편집한 고퀄리
            <br />티 상품소개 영상을 자동 생성해서 운영하시는 채널에
            <br />
            업로드까지 마무리 해드려요!
          </p>
          {!isMobile && (
            <div>
              <Button
                className={classNames(
                  ' px-[23px] py-[13px] border-none rounded-[48px] text-white font-bold',
                  {
                    'bg-href': !userInfo,
                    'bg-main': userInfo,
                  }
                )}
                onClick={() => {
                  onClickTextArea('/video-editor')
                }}
              >
                {userInfo ? '지금 시작하기 >' : `가입하고 쿠폰팩 뽑기`}
              </Button>
            </div>
          )}
        </div>
        <div className="w-[100%] md:w-[50%] flex items-center">
          <img
            src="assets/img/home_picture1.png"
            width={388}
            height={232}
            alt="home1"
          />
        </div>
        {isMobile && (
          <div className="flex justify-end">
            <Button
              className={classNames(
                ' px-[23px] py-[13px] border-none rounded-[48px] text-white font-bold',
                {
                  'bg-href': !userInfo,
                  'bg-main': userInfo,
                }
              )}
              onClick={() => {
                onClickTextArea('/video-editor')
              }}
            >
              {userInfo ? '지금 시작하기 >' : `가입하고 쿠폰팩 뽑기`}
            </Button>
          </div>
        )}

        {isFreePlanModal && (
          <FreePlanModal
            isFreePlanModal={isFreePlanModal}
            setFreePlanModal={SetFreePlanModal}
          />
        )}
        {isPlan && (
          <Modal isModal={isPlan}>
            <div className="bg-white w-[400px] h-[184px] p-[20px] border-t rounded-t-[20px]">
              <div className="flex flex-col items-center justify-center h-full gap-[24px]">
                <p className="font-bold text-[18px]">
                  이미 사용중인 플랜이 있어요!
                </p>
                <div>
                  <p className="">
                    사용중인 플랜의 기한이 지난 후<br /> 새로운 플랜을
                    결제해주시거나 <br />
                    패스트컷에 따로 문의해주세요!
                  </p>
                </div>
              </div>
            </div>
            <div className="w-[400px] bg-main h-[60px] border-b border-main rounded-b-[20px] flex justify-center items-center">
              <button
                className="text-white w-[400px]"
                onClick={() => {
                  setIsPlan(false)
                }}
              >
                확인
              </button>
            </div>
          </Modal>
        )}
      </div>
      <div
        className="home_fifth flex flex-col items-center justify-center gap-[16px] w-[100vw] h-[680px] mb-[80px] "
        style={{
          backgroundImage: 'url(./assets/img/home/fifth.png)',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <p className="text-white text-[24px]">
          숏폼을 시작하는 가장 효율적인 방법
        </p>
        <p className="text-white font-[900] text-[36px] text-center leading-[47.84px]">
          패스트컷 AI 숏폼으로
          <br />
          나의 채널에 색을 입히고 <br />
          빠르게 성장하기
        </p>
        <div>
          {' '}
          <button
            className={classNames(
              ' px-[24px] py-[13px] font-bold rounded-[40px] bg-white mt-[24px] md:w-[388px] w-[375px]',
              {
                'text-short': !userInfo,
                'text-main': userInfo,
              }
            )}
            onClick={() => {
              onClickTextArea('/short-video-editor')
            }}
          >
            {userInfo ? '지금 시작하기' : ' 가입하고 숏폼 무료체험 '}
          </button>
        </div>
      </div>
      <div className="home_first flex flex-col items-center gap-[16px] w-[343px] md:w-[816px] mb-[80px] md:mb-[80px]">
        <div className="flex flex-col items-center">
          <p className="md:text-[60px] text-[40px]">링크만 있으면,</p>
          <p
            className="md:text-[64px]  font-[800] text-[48px] text-center"
            style={{
              background: 'linear-gradient(90deg, #6095FF, #D512E7)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            AI가 만들어주는 컨텐츠 숏폼!
          </p>
          <p className="text-[18px] text-center font-bold mt-[24px]">
            패스트컷 AI로 가장 쉽고 빠르게 바이럴용 컨텐츠 숏폼을 만들어보세요.
          </p>

          <button
            className={classNames(
              ' px-[24px] py-[13px] font-bold rounded-[40px] text-white mt-[24px]',
              {
                'bg-short': !userInfo,
                'bg-main': userInfo,
              }
            )}
            onClick={() => {
              onClickTextArea('/short-video-editor')
            }}
          >
            {userInfo ? '지금시작하기 >' : '가입하고 숏폼 무료체험'}
          </button>
        </div>
      </div>
      <div className="home_second flex flex-col items-center gap-[16px] w-[343px] md:w-[816px] mb-[100px] ">
        <video
          playsInline
          autoPlay
          muted
          loop
          className="rounded-[10px]"
          src="assets/video/home5.mp4"
        />
      </div>
      <div className="home_second flex flex-col items-center gap-[16px] w-[343px] md:w-[816px] mb-[100px] ">
        <p className="text-[40px] font-[800] text-center">
          Image to Video <br /> AI가 만들어주는 고퀄리티 영상
        </p>
        <video
          playsInline
          autoPlay
          muted
          loop
          className="rounded-[40px]"
          src="assets/video/runway.mp4"
        />
      </div>
      <div className="w-[100vw] flex justify-center items-end mb-[100px] relative">
        <img
          src={
            isMobile
              ? 'assets/img/home/mobile_home.png'
              : 'assets/img/gradient_bg.png'
          }
          alt="bg"
          className=" absolute -z-10"
        />
        <div className="home_second flex flex-col items-center gap-[16px] w-[343px] md:w-[816px] mb-[100px] z-30">
          <div className="flex flex-col items-center">
            <p
              className="md:text-[40px] text-[34px] font-[800] text-center
            "
            >
              숏폼으로 {isMobile && <br />}내 채널을 키우세요! 🔥
            </p>
            <div className="flex gap-6 mt-6">
              <img
                src="assets/img/icon_shorts.png"
                alt="short"
                width={66}
                height={66}
              />
              <img
                src="assets/img/icon_instagram.png"
                alt="short"
                width={66}
                height={66}
              />
              <img
                src="assets/img/icon_naver_clip.png"
                alt="short"
                width={66}
                height={66}
              />
              <img
                src="assets/img/icon_tiktok.png"
                alt="short"
                width={66}
                height={66}
              />
            </div>
            <p className="text-center mt-[18px] font-bold">
              무엇을 기다리세요? <br />
              링크만 하나 복사 해오세요!
            </p>
            <div className="flex md:gap-[18px] gap-1 mt-6 justify-center">
              <video
                controls
                playsInline
                autoPlay
                muted
                loop
                className="border rounded-[4px]"
                src="assets/video/main_s_01.mp4"
                width={isMobile ? 119.49 : 260}
              />
              <video
                playsInline
                controls
                autoPlay
                muted
                loop
                className="border rounded-[4px]"
                src="assets/video/main_s_02.mp4"
                width={isMobile ? 119.49 : 260}
              />
              <video
                playsInline
                controls
                autoPlay
                muted
                loop
                className="border rounded-[4px]"
                src="assets/video/main_s_03.mp4"
                width={isMobile ? 119.49 : 260}
              />
            </div>
          </div>
        </div>
      </div>{' '}
      <div className="home_third flex flex-col md:flex-row items-center w-[343px] md:w-[816px] mb-[100px] h-[650px] md:h-[383px] bg-[#F6F8FB] rounded-[20px] p-10  relative">
        <div className="flex flex-col justify-between w-full md:w-[50%] md:h-full">
          <div className="flex flex-col">
            <p className="font-bold text-[24px]">쇼츠, 릴스, 클립, 틱톡?</p>
            <p className="mt-2">
              숏폼 형태의 영상은 유튜브 쇼츠, 인스타그램 릴스, 네이버 클립,
              틱톡에서 엄청난 인기를 얻고 있어요. 내가 만든 숏폼을 부담 없이
              여러 플랫폼에 퍼트려서 내 컨텐츠와 맞는 뷰어들을 찾아보세요. 나와
              찰떡 궁합인 구독자를 만날 수 있을 거에요!
            </p>
          </div>
          <div>
            <button
              className={classNames(
                ' px-[24px] py-[13px] font-bold rounded-[40px] text-white mt-[24px]',
                {
                  'bg-short': !userInfo,
                  'bg-main': userInfo,
                }
              )}
              onClick={() => {
                onClickTextArea('/short-video-editor')
              }}
            >
              {userInfo ? '지금시작하기 >' : '가입하고 숏폼 무료체험'}
            </button>
          </div>
        </div>
        <div className="flex flex-col justify-end w-full md:w-[50%]">
          <img
            src="assets/img/img_group.png"
            alt="group"
            className="absolute -bottom-2 -right-2"
            width={338}
            height={335}
          />
        </div>
      </div>
      <div className="home_fourth flex flex-col items-center gap-[16px] w-[343px] md:w-[816px] mb-[80px] md:mb-[80px]">
        <div className="flex flex-col items-center">
          <p className="md:text-[48px] text-[34px] font-bold text-center">
            <span className="text-main">패스트컷 AI</span> {isMobile && <br />}{' '}
            3클릭 숏폼 만들기
          </p>
        </div>
        <div className="flex flex-col md:flex-row gap-2 ">
          <div className="first flex flex-col bg-[#F6F8FB] p-5 rounded-[10px] gap-2 w-full md:w-[33%]  h-[240px] md:h-auto">
            <div className="flex justify-between">
              <div className="pb-6">
                <p className="font-[800] text-[18px]">컨텐츠 링크 복사</p>
                <p className="text-[14px]">
                  숏폼으로 만들고싶은
                  <br /> 내용의 페이지 링크를 복사
                  <br />
                  해오세요!{' '}
                </p>
              </div>
              <div>
                <img
                  src="assets/img/img_first.png"
                  alt="first"
                  width={64}
                  height={67.89}
                />
              </div>
            </div>
            <div className="bg-white rounded-[10px] p-4 ">
              <p className="font-bold text-main">
                https://my.link.com / article/025/02452
              </p>
            </div>
          </div>
          <div className="second flex flex-col bg-[#F6F8FB] p-5 rounded-[10px] gap-2  w-full md:w-[33%] relative  h-[240px] md:h-auto">
            <div className="flex justify-between">
              <div>
                <p className="font-[800] text-[18px]">숏폼 제목 입력</p>
                <p className="text-[14px]">
                  관심을 모을 수 있고, 컨텐츠
                  <br />와 어울리는 제목을 입력해
                  <br />서 숏폼의 조회수를 높여보
                  <br />
                  세요!
                </p>
              </div>
              <div>
                <img
                  src="assets/img/img_second.png"
                  alt="first"
                  width={64}
                  height={67.89}
                />
              </div>
            </div>
            <div className=" ">
              <img
                className=" absolute bottom-0 left-5"
                src="assets/img/img_second_description.png"
                alt="description"
                width={126}
                height={108}
              />
            </div>
          </div>{' '}
          <div className="third flex flex-col bg-[#F6F8FB] p-5 rounded-[10px] gap-2 w-full md:w-[33%] h-[240px] md:h-auto relative">
            <div className="flex justify-between">
              <div>
                <p className="font-[800] text-[18px]">효과 선택</p>
                <p className="text-[14px]">
                  패스트컷이 제공하는 다양한
                  <br /> 효과를 선택하세요! <br />
                  말투, 목소리, 배경영상, 배<br />
                  경음악 등을 나의 영상과 채<br />널 스타일에 맞게 세팅 해놓
                  <br />고 사용하실 수 있습니다.
                </p>
              </div>
              <div>
                <img
                  src="assets/img/img_third.png"
                  alt="first"
                  width={64}
                  height={67.89}
                />
              </div>
            </div>
            <img
              className=" absolute bottom-0 right-0"
              src="assets/img/home/img_third_description.png "
              alt="description"
              width={170}
              height={272}
            />
          </div>
        </div>
      </div>
      <div className="home_second flex flex-col items-center gap-[16px] w-full md:w-[1102px] mb-[100px] ">
        <img src="assets/img/kuly.png" alt="" />
      </div>
      <FAQ />
      <div className="flex flex-col p-[20px] items-center relative  bg-href w-[100vw] md:p-[40px]">
        <div className="flex flex-col items-center text-center gap-[8px] md:h-[119px]">
          <p className="text-[24px] text-white">
            패스트컷 AI로 시작하는 쿠팡 파트너스 유튜브, 아직 망설여지나요?
          </p>
          <p className="font-[900] text-[36px] text-white leading-[36px]">
            지금 회원가입 하시고,
            <br />
            롱폼 무료체험으로 모든 기능을 써보고 결정하세요.
          </p>
        </div>
        <div className="flex flex-col md:flex-row justify-end md:h-[60px] gap-[8px] text-[18px] font-bold mt-[32px]">
          <FreeButton
            className="w-[333px] h-[60px]  border-white border-[2px] rounded-[40px] text-white"
            onClick={() => {
              SetFreePlanModal((prev) => !prev)
            }}
          >
            체험내용 상세보기
          </FreeButton>
          <button
            className="w-[333px] h-[60px] bg-white  rounded-[40px] text-href"
            onClick={paymentFreePlan}
          >
            지금 바로 체험하기
          </button>
        </div>
      </div>
      <Modal isModal={isInAppBrowserModal}>
        <div className="bg-white w-[400px] h-[184px] p-[20px] border-t rounded-t-[20px]">
          <div className="flex flex-col items-center justify-center h-full gap-[24px]">
            <p className="font-bold text-[18px]">주의</p>
            <div className="flex flex-col leading-[22px]">
              <p className=" text-[14px] ">
                인앱 브라우져환경에서는 회원가입이 안되요! <br />
              </p>
            </div>
          </div>
        </div>
        <div className="w-[400px] text-[14px]  h-[60px]  flex justify-center items-center">
          <button
            className="text-white w-full h-full bg-main rounded-b-[20px] border-b border-main font-bold"
            onClick={() => {
              handleOpenExternalBrowser()
            }}
          >
            확인
          </button>
        </div>
      </Modal>
      {popupContent?.status && !cookies?.fast_cut_popup && (
        <HomePopup
          isPopupModal={isPopupModal}
          setPopupModal={setPopupModal}
          popupContent={popupContent}
        />
      )}
    </div>
  )
}

export default Home
