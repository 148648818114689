import { UseQueryResult, useMutation, useQuery } from '@tanstack/react-query'
import { getList, postList } from '../../service/list'
import { authGoogle } from '../../service/auth'
import { acceptedTerms, getProfile, registInviteCode } from '../../service/user'

function useRegistInviteCode() {
  return useMutation({
    mutationKey: ['regist-invte-code'],
    mutationFn: registInviteCode,
  })
}

export default useRegistInviteCode
