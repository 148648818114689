import { Dispatch, PropsWithChildren, SetStateAction } from 'react'
import { Modal } from './layout/Modal'
import { useLocation, useNavigate } from 'react-router-dom'
interface ConfirmPaymentCashModalProps {
  isModal: boolean
  setModal: Dispatch<SetStateAction<boolean>>
  amount: number
  valid: string
  promotionCode: number
  bonusValue: number
}
const ConfirmPaymentCashModal = ({
  children,
  isModal,
  setModal,
  amount,
  valid,
  promotionCode,
  bonusValue,
}: PropsWithChildren<ConfirmPaymentCashModalProps>) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const calculatePromotion = (amount: number) => {
    let newAmount = Number(amount)
    if (valid === 'true') {
      newAmount = amount + amount * (promotionCode / 100)
    }
    newAmount = newAmount + bonusValue

    return newAmount
  }
  return (
    <Modal isModal={isModal}>
      <div className="bg-white w-[400px] h-[184px] p-[20px] border-t rounded-t-[20px]">
        <div className="flex flex-col items-center justify-center h-full gap-[24px]">
          <p className="font-bold text-[18px]">©️ 캐시 충전</p>
          <div className="flex flex-col leading-[22px]">
            <p className=" text-[14px] ">
              <span className="font-bold text-main">
                {calculatePromotion(amount).toLocaleString()} 캐시
              </span>
              가 충전되었어요!
            </p>
          </div>
        </div>
      </div>
      <div className="w-[400px] text-[14px]  h-[60px]  flex justify-center items-center">
        <button
          className="text-white w-[100%] h-full bg-main rounded-b-[20px] border-b border-main"
          onClick={() => {
            setModal((prev) => !prev)
            navigate(`${pathname}?mType=true`)
          }}
        >
          확인
        </button>
      </div>
    </Modal>
  )
}

export default ConfirmPaymentCashModal
